export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const SET_TOKEN = 'SET_TOKEN';
export const TOKEN_FAIL = 'TOKEN_FAIL';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
