import React, {useContext} from 'react';
import AlertContext from '../../context/alert/alertContext';

const Alerts = () => {
    const alertContext = useContext(AlertContext);
    const { alert } = alertContext;

    return (
        (alert !== null)
        ? (
            <div className='all-center' style={styles.errorStyle}>
                <label className='text-center'>{alert.msg}</label>
            </div>
        )
        : null
    );
};

const styles = {
    errorStyle: {
        position: 'relative',
        alignSelf: 'center',
        color: 'red',
    }
};

export default Alerts;
