import React, {Fragment, useEffect, useState} from 'react';
import {Collapse} from 'react-collapse';
import Sidebar from '../layout/SideBar';
import PropTypes from 'prop-types';
import '../Styles/App.css';

const FactorDetail = ({ factor }) => {
    const { id, factor_number, user_ID, firstName, lastName, email,
        orderType, price, status, createDateTime} = factor;

    const [detailOpen, setDetailOpen] = useState(false);
    const [icon, setIcon] = useState('fa fa-angle-down');

    useEffect(() => {
        if (detailOpen) {
            setIcon('fa fa-angle-up')
        } else {
            setIcon('fa fa-angle-down')
        }
    },[detailOpen]);

    const orderTypeOptions = ['Card & SIMCard' , 'Card', 'SIMCard'];

    return (
        <Fragment>
            <Sidebar/>
            <div style={styles.containerStyle}>
                <div className='grid-6'>
                    <div style={{paddingLeft: 5, paddingTop: 10, width: '50%'}}>{id}</div>
                    <div style={{paddingLeft: 5, marginLeft: '-50%'}}>
                        {factor_number.slice(0,22)}<br/>{factor_number.slice(22)}
                    </div>
                    <div style={{paddingLeft: 5, paddingTop: 10}}>{firstName}</div>
                    <div style={{paddingLeft: 5, paddingTop: 10}}>{lastName}</div>
                    <div style={{paddingLeft: 5, paddingTop: 6}}>
                        {!status
                            ? <i className='fa fa-times-circle'
                                 style={{fontSize: 16, color: 'red', paddingLeft: '9%', paddingTop: 10}}/>
                            : <i className='fa fa-check-circle'
                                 style={{fontSize: 16, color: 'green', paddingLeft: '9%', paddingTop: 10}}/>
                        }
                    </div>
                    <div style={{paddingLeft: 5, paddingTop: 6}}>
                        <button
                            className='btn'
                            style={styles.btnStyle}
                            onClick={(e) => {
                                e.preventDefault();
                                setDetailOpen(!detailOpen)
                            }}
                        >
                            Details <i className={icon} />
                        </button>
                    </div>
                </div>

                <Collapse isOpened={detailOpen}>
                    <div className='grid-2' style={styles.detailStyle}>
                        <div style={styles.col1Style}>
                            <div><strong>ID:</strong></div>
                            <div><strong>Factor Number:</strong></div>
                            <div>
                                <strong>User ID: </strong>
                                <label style={{fontSize: 10, color: 'gray'}}>
                                    (The user who registered order)
                                </label>
                            </div>
                            <div><strong>First Name:</strong></div>
                            <div><strong>Last Name:</strong></div>
                            <div><strong>Email:</strong></div>
                            <div><strong>Order Type:</strong></div>
                            <div><strong>Price:</strong></div>
                            <div><strong>Status:</strong></div>
                            <div><strong>Create Date Time:</strong></div>
                        </div>
                        <div style={styles.col2Style}>
                            <div>{id}</div>
                            <div>{factor_number}</div>
                            <div>{user_ID}</div>
                            <div>{firstName}</div>
                            <div>{lastName}</div>
                            <div>{email}</div>
                            <div>{orderTypeOptions[orderType-1]}</div>
                            <div>{price}</div>
                            <div>
                                {!status
                                    ? 'unpaid'
                                    : 'paid'
                                }
                            </div>
                            <div>{createDateTime.slice(0,10)}</div>
                        </div>
                    </div>
                </Collapse>
            </div>
        </Fragment>
    );
};

FactorDetail.prototype = {
    factor: PropTypes.object.isRequired,
};

const styles = {
    containerStyle: {
        backgroundColor: '#eee',
        borderStyle: 'solid',
        borderColor: 'gray',
        borderWidth: 2,
        borderTopWidth: 0
    },
    btnStyle: {
        backgroundColor: '#2c3c85',
        color: 'white',
        height: 35,
        width: 75,
        padding: 0,
        margin: 2,
        borderRadius: 5,
    },
    detailStyle: {
        backgroundColor: 'white',
        padding: 10,
        marginTop: 2,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 5,
    },
    col1Style: {
        position: 'relative',
        width: '50%'
    },
    col2Style: {
        position: 'relative',
        width: '100%',
        marginLeft: '-50%'
    }
};

export default FactorDetail;
