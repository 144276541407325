import React, {Fragment, useContext, useEffect, useState} from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import axios from 'axios';
import '../Styles/App.css';
import TokenContext from '../../context/token/tokenContext';
import AlertContext from '../../context/alert/alertContext';
import Sidebar from '../layout/SideBar';
import Spinner from '../layout/Spinner';
import Alerts from '../layout/Alerts';
import TouristDetails2 from '../layout/TouristDetails2';
import DialogBox from '../layout/DialogBox';
import history from "../history";

const RegisteredTouristsList2 = () => {
    const t = localStorage.getItem('token');
    const [adminsID, setAdminsID] = useState([]);
    const [res, setRes] = useState(null);

    const auth = async () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        try {
            const admins = await axios.get('https://admin.intravelcard.com/api/auth/getAdmins/', config);
            admins.data.map(admin => adminsID.push(admin.id));
            const userId_token = await axios.get(`https://admin.intravelcard.com/api/auth/isAdmin/${t}`, config);
            if (userId_token.data[0]) {
                const TFList = adminsID.map(id => id === userId_token.data[0].user);
                if (TFList.includes(true)) {
                    setRes(true);
                } else {
                    setRes(false);
                    history.push('/')
                }
            } else {
                setRes(false);
            }
        } catch (err) {
            console.log('Error!')
        }
    };
    const [haveRes, setHaveRes] = useState(false);

    const tokenContext = useContext(TokenContext);
    const { bankToken, tokenError, setToken, clearErrors } = tokenContext;
    const [haveToken, setHaveToken] = useState(false);

    const alertContext = useContext(AlertContext);
    const { setAlert } = alertContext;

    const [load, setLoad] = useState(null);

    const countries = [
        {
            title: 'Afghanistan',
            persianName: 'افغانستان',
            fidaId: 1,
            arabian: false
        },
        {
            title: 'Albania',
            persianName: 'آلبانی',
            fidaId: 2,
            arabian: false
        },
        {
            title: 'Algeria',
            persianName: 'الجزایر',
            fidaId: 3,
            arabian: true
        },
        {
            title: 'American Samoa',
            persianName: 'ساموای آمریکا',
            fidaId: 4,
            arabian: false
        },
        {
            title: 'Andorra',
            persianName: 'آندورا',
            fidaId: 5,
            arabian: false
        },
        {
            title: 'Angola',
            persianName: 'آنگولا',
            fidaId: 6,
            arabian: false
        },
        {
            title: 'Anguilla',
            persianName: 'آنگوئیلا',
            fidaId: 7,
            arabian: false
        },
        {
            title: 'Antarctica',
            persianName: 'آنتارکتیکا',
            fidaId: 8,
            arabian: false
        },
        {
            title: 'Antigua and Barbuda',
            persianName: 'آنتیگوا و باربودا',
            fidaId: 9,
            arabian: false
        },
        {
            title: 'Argentina',
            persianName: 'آرژانتین',
            fidaId: 10,
            arabian: false
        },
        {
            title: 'Armenia',
            persianName: 'ارمنستان',
            fidaId: 11,
            arabian: false
        },
        {
            title: 'Aruba',
            persianName: 'آروبا',
            fidaId: 12,
            arabian: false
        },
        {
            title: 'Australia',
            persianName: 'استرالیا',
            fidaId: 13,
            arabian: false
        },
        {
            title: 'Austria',
            persianName: 'اتریش',
            fidaId: 14,
            arabian: false
        },
        {
            title: 'Azerbaijan',
            persianName: 'آذربایجان',
            fidaId: 15,
            arabian: false
        },
        {
            title: 'Bahamas',
            persianName: 'باهاما',
            fidaId: 16,
            arabian: false
        },
        {
            title: 'Bahrain',
            persianName: 'بحرین',
            fidaId: 17,
            arabian: true
        },
        {
            title: 'Bangladesh',
            persianName: 'بنگلادش',
            fidaId: 18,
            arabian: false
        },
        {
            title: 'Barbados',
            persianName: 'باربادوس',
            fidaId: 19,
            arabian: false
        },
        {
            title: 'Belarus',
            persianName: 'بلاروس',
            fidaId: 20,
            arabian: false
        },
        {
            title: 'Belgium',
            persianName: 'بلژیک',
            fidaId: 21,
            arabian: false
        },
        {
            title: 'Belize',
            persianName: 'بلیز',
            fidaId: 22,
            arabian: false
        },
        {
            title: 'Benin',
            persianName: 'بنین ',
            fidaId: 23,
            arabian: false
        },
        {
            title: 'Bermuda',
            persianName: 'برمودا',
            fidaId: 24,
            arabian: false
        },
        {
            title: 'Bhutan',
            persianName: 'بوتان',
            fidaId: 25,
            arabian: false
        },
        {
            title: 'Bolivia',
            persianName: 'بولیوی ',
            fidaId: 26,
            arabian: false
        },
        {
            title: 'Bosnia and Herzegovina',
            persianName: 'بوسنی وهرزگوین',
            fidaId: 27,
            arabian: false
        },
        {
            title: 'Botswana',
            persianName: 'بوتسوانا',
            fidaId: 28,
            arabian: false
        },
        {
            title: 'Bouvet Island',
            persianName: 'جزیره بووت',
            fidaId: 29,
            arabian: false
        },
        {
            title: 'Brazil',
            persianName: 'برزیل',
            fidaId: 30,
            arabian: false
        },
        {
            title: 'British Indian Ocean Territory',
            persianName: 'سرزمین انگلیسی اقیانوس هند',
            fidaId: 31,
            arabian: false
        },
        {
            title: 'Brunei',
            persianName: 'برونئی',
            fidaId: 32,
            arabian: false
        },
        {
            title: 'Bulgaria',
            persianName: 'بلغارستان',
            fidaId: 33,
            arabian: false
        },
        {
            title: 'Burkina Faso',
            persianName: 'بورکینافاسو',
            fidaId: 34,
            arabian: false
        },
        {
            title: 'Burundi',
            persianName: 'بوروندی ',
            fidaId: 35,
            arabian: false
        },
        {
            title: 'Ivory Coast',
            persianName: 'ساحل عاج',
            fidaId: 36,
            arabian: false
        },
        {
            title: 'Cambodia',
            persianName: 'کامبوج',
            fidaId: 37,
            arabian: false
        },
        {
            title: 'Cameroon',
            persianName: 'کامرون',
            fidaId: 38,
            arabian: false
        },
        {
            title: 'Canada',
            persianName: 'کانادا',
            fidaId: 39,
            arabian: false
        },
        {
            title: 'Cape Verde',
            persianName: 'کیپ ورد',
            fidaId: 40,
            arabian: false
        },
        {
            title: 'Cayman Islands',
            persianName: 'جزایر کیمن',
            fidaId: 41,
            arabian: false
        },
        {
            title: 'Central African Republic',
            persianName: 'آفریقای مرکزی',
            fidaId: 42,
            arabian: false
        },
        {
            title: 'Chad',
            persianName: 'چاد',
            fidaId: 43,
            arabian: false
        },
        {
            title: 'Chile',
            persianName: 'شیلی ',
            fidaId: 45,
            arabian: false
        },
        {
            title: 'China',
            persianName: 'چین',
            fidaId: 46,
            arabian: false
        },
        {
            title: 'Christmas Island',
            persianName: 'جزیره کریسمس (‌استرالیا)',
            fidaId: 47,
            arabian: false
        },
        {
            title: 'Cocos (Keeling) Islands',
            persianName: 'جزایر (‌کلینیگ) کوکوس',
            fidaId: 48,
            arabian: false
        },
        {
            title: 'Colombia',
            persianName: 'کلمبیا',
            fidaId: 49,
            arabian: false
        },
        {
            title: 'Comoros',
            persianName: 'کومورو(قمر)',
            fidaId: 50,
            arabian: true
        },
        {
            title: 'Congo',
            persianName: 'کنگو',
            fidaId: 51,
            arabian: false
        },
        {
            title: 'congo brazzaville',
            persianName: 'برازاویل کنگو',
            fidaId: 52,
            arabian: false
        },
        {
            title: 'Cook Islands',
            persianName: 'جزایر کوک',
            fidaId: 53,
            arabian: false
        },
        {
            title: 'Costa Rica',
            persianName: 'کاستاریکا',
            fidaId: 55,
            arabian: false
        },
        {
            title: 'Croatia',
            persianName: 'کروواسی',
            fidaId: 56,
            arabian: false
        },
        {
            title: 'Cuba',
            persianName: 'کوبا',
            fidaId: 57,
            arabian: false
        },
        {
            title: 'Cyprus',
            persianName: 'قبرس',
            fidaId: 58,
            arabian: false
        },
        {
            title: 'Czech Republic',
            persianName: 'چک',
            fidaId: 59,
            arabian: false
        },
        {
            title: 'Denmark',
            persianName: 'دانمارک',
            fidaId: 60,
            arabian: false
        },
        {
            title: 'Djibouti',
            persianName: 'جیبوتی',
            fidaId: 61,
            arabian: true
        },
        {
            title: 'Dominica',
            persianName: 'دومینیکا',
            fidaId: 62,
            arabian: false
        },
        {
            title: 'Dominican Republic',
            persianName: 'دومینیکن',
            fidaId: 63,
            arabian: false
        },
        {
            title: 'East Timor',
            persianName: 'تیمور شرقی',
            fidaId: 64,
            arabian: false
        },
        {
            title: 'Ecuador',
            persianName: 'اکوادور',
            fidaId: 65,
            arabian: false
        },
        {
            title: 'Egypt',
            persianName: 'مصر',
            fidaId: 66,
            arabian: true
        },
        {
            title: 'El Salvador',
            persianName: 'السالوادور',
            fidaId: 67,
            arabian: false
        },
        {
            title: 'Equatorial Guinea',
            persianName: 'گینه استوائی',
            fidaId: 68,
            arabian: false
        },
        {
            title: 'Eritrea',
            persianName: 'اریتره',
            fidaId: 69,
            arabian: false
        },
        {
            title: 'Estonia',
            persianName: 'استونی',
            fidaId: 70,
            arabian: false
        },
        {
            title: 'Ethiopia',
            persianName: 'اتیوپی (حبشه)',
            fidaId: 71,
            arabian: false
        },
        {
            title: 'Falkland Islands (Islas Malvinas)',
            persianName: 'جزایر فالکند - مالویناس',
            fidaId: 72,
            arabian: false
        },
        {
            title: 'Faroe Islands',
            persianName: 'جزایر فائرو',
            fidaId: 73,
            arabian: false
        },
        {
            title: 'Fiji Islands',
            persianName: 'فیجی',
            fidaId: 74,
            arabian: false
        },
        {
            title: 'Finland',
            persianName: 'فنلاند',
            fidaId: 75,
            arabian: false
        },
        {
            title: 'France',
            persianName: 'فرانسه',
            fidaId: 76,
            arabian: false
        },
        {
            title: 'French Guiana',
            persianName: 'سرزمین گوئیانا فرانسه',
            fidaId: 78,
            arabian: false
        },
        {
            title: 'French Polynesia',
            persianName: 'پلی‌نزی فرانسه تاهیتی',
            fidaId: 79,
            arabian: false
        },
        {
            title: 'French Southern and Antarctic Lands',
            persianName: 'سرزمین‌های قطب جنوب و جنوبی فرانسه',
            fidaId: 80,
            arabian: false
        },
        {
            title: 'Gabon',
            persianName: 'گابون',
            fidaId: 81,
            arabian: false
        },
        {
            title: 'Gambia',
            persianName: 'گامبیا',
            fidaId: 82,
            arabian: false
        },
        {
            title: 'Georgia',
            persianName: 'گرجستان',
            fidaId: 83,
            arabian: false
        },
        {
            title: 'Germany',
            persianName: 'آلمان',
            fidaId: 84,
            arabian: false
        },
        {
            title: 'Ghana',
            persianName: 'غنا',
            fidaId: 85,
            arabian: false
        },
        {
            title: 'Gibraltar',
            persianName: 'جبل الطارق',
            fidaId: 86,
            arabian: false
        },
        {
            title: 'Greece',
            persianName: 'یونان',
            fidaId: 87,
            arabian: false
        },
        {
            title: 'Greenland',
            persianName: 'گرینلند',
            fidaId: 88,
            arabian: false
        },
        {
            title: 'Grenada',
            persianName: 'گرنادا',
            fidaId: 89,
            arabian: false
        },
        {
            title: 'Guadeloupe',
            persianName: 'گوادلوپ',
            fidaId: 90,
            arabian: false
        },
        {
            title: 'Guam',
            persianName: 'گوآم',
            fidaId: 91,
            arabian: false
        },
        {
            title: 'Guatemala',
            persianName: 'گواتمالا',
            fidaId: 92,
            arabian: false
        },
        {
            title: 'Guernsey',
            persianName: 'گرنزی',
            fidaId: 93,
            arabian: false
        },
        {
            title: 'Guinea',
            persianName: 'گینه',
            fidaId: 94,
            arabian: false
        },
        {
            title: 'Guinea-Bissau',
            persianName: 'گینه بیسائو',
            fidaId: 95,
            arabian: false
        },
        {
            title: 'Guyana',
            persianName: 'گویان',
            fidaId: 96,
            arabian: false
        },
        {
            title: 'Haiti',
            persianName: 'هائیتی',
            fidaId: 97,
            arabian: false
        },
        {
            title: 'Heard Island and McDonald Islands',
            persianName: 'جزیره هرد و جزایر مک‌دونالد',
            fidaId: 98,
            arabian: false
        },
        {
            title: 'Honduras',
            persianName: 'هندوراس',
            fidaId: 99,
            arabian: false
        },
        {
            title: 'Hong Kong SAR',
            persianName: 'هنگ کنگ',
            fidaId: 100,
            arabian: false
        },
        {
            title: 'Hungary',
            persianName: 'مجارستان',
            fidaId: 101,
            arabian: false
        },
        {
            title: 'Iceland',
            persianName: 'ایسلند',
            fidaId: 102,
            arabian: false
        },
        {
            title: 'India',
            persianName: 'هند',
            fidaId: 103,
            arabian: false
        },
        {
            title: 'Indonesia',
            persianName: 'اندونزی',
            fidaId: 104,
            arabian: false
        },
        {
            title: 'Iran',
            persianName: 'ایران (جمهوری اسلامی)',
            fidaId: 105,
            arabian: false
        },
        {
            title: 'Iraq',
            persianName: 'عراق',
            fidaId: 106,
            arabian: true
        },
        {
            title: 'Ireland',
            persianName: 'ایرلند',
            fidaId: 107,
            arabian: false
        },
        {
            title: 'Italy',
            persianName: 'ایتالیا',
            fidaId: 109,
            arabian: false
        },
        {
            title: 'Jamaica',
            persianName: 'جامائیکا',
            fidaId: 110,
            arabian: false
        },
        {
            title: 'Japan',
            persianName: 'ژاپن ',
            fidaId: 111,
            arabian: false
        },
        {
            title: 'Jersey',
            persianName: 'جرزی',
            fidaId: 112,
            arabian: false
        },
        {
            title: 'Jordan',
            persianName: 'اردن',
            fidaId: 113,
            arabian: true
        },
        {
            title: 'Kazakhstan',
            persianName: 'قزاقستان',
            fidaId: 114,
            arabian: false
        },
        {
            title: 'Kenya',
            persianName: 'کنیا',
            fidaId: 115,
            arabian: false
        },
        {
            title: 'Kiribati',
            persianName: 'کیریباتی',
            fidaId: 116,
            arabian: false
        },
        {
            title: 'Korea',
            persianName: 'کره جنوبی',
            fidaId: 117,
            arabian: false
        },
        {
            title: 'Kuwait',
            persianName: 'کویت',
            fidaId: 118,
            arabian: true
        },
        {
            title: 'Kyrgyzstan',
            persianName: 'قرقیزستان',
            fidaId: 119,
            arabian: false
        },
        {
            title: 'Laos',
            persianName: 'لائوس',
            fidaId: 120,
            arabian: false
        },
        {
            title: 'Latvia',
            persianName: 'لاتویا',
            fidaId: 121,
            arabian: false
        },
        {
            title: 'Lebanon',
            persianName: 'لبنان',
            fidaId: 122,
            arabian: true
        },
        {
            title: 'Lesotho',
            persianName: 'لسوتو',
            fidaId: 123,
            arabian: false
        },
        {
            title: 'Liberia',
            persianName: 'لیبریا',
            fidaId: 124,
            arabian: false
        },
        {
            title: 'Libya',
            persianName: 'لیبی',
            fidaId: 125,
            arabian: true
        },
        {
            title: 'Liechtenstein',
            persianName: 'لیختن اشتاین',
            fidaId: 126,
            arabian: false
        },
        {
            title: 'Lithuania',
            persianName: 'لیتوانی',
            fidaId: 127,
            arabian: false
        },
        {
            title: 'Luxembourg',
            persianName: 'لوکزامبورگ',
            fidaId: 128,
            arabian: false
        },
        {
            title: 'Macau SAR',
            persianName: 'ماکائو',
            fidaId: 129,
            arabian: false
        },
        {
            title: 'Macedonia',
            persianName: 'مقدونیه',
            fidaId: 130,
            arabian: false
        },
        {
            title: 'Madagascar',
            persianName: 'ماداگاسکار',
            fidaId: 131,
            arabian: false
        },
        {
            title: 'Malawi',
            persianName: 'مالاوی',
            fidaId: 132,
            arabian: false
        },
        {
            title: 'Malaysia',
            persianName: 'مالزی',
            fidaId: 133,
            arabian: false
        },
        {
            title: 'Maldives',
            persianName: 'مالدیو',
            fidaId: 134,
            arabian: false
        },
        {
            title: 'Mali',
            persianName: 'مالی',
            fidaId: 135,
            arabian: false
        },
        {
            title: 'Malta',
            persianName: 'مالت',
            fidaId: 136,
            arabian: false
        },
        {
            title: 'Isle of Man',
            persianName: 'جزیره من',
            fidaId: 137,
            arabian: false
        },
        {
            title: 'Marshall Islands',
            persianName: 'جزایر مارشال',
            fidaId: 138,
            arabian: false
        },
        {
            title: 'Martinique',
            persianName: 'مارتینیک',
            fidaId: 139,
            arabian: false
        },
        {
            title: 'Mauritania',
            persianName: 'موریتانی',
            fidaId: 140,
            arabian: true
        },
        {
            title: 'Mauritius',
            persianName: 'موریس',
            fidaId: 141,
            arabian: false
        },
        {
            title: 'Mayotte',
            persianName: 'مایوت',
            fidaId: 142,
            arabian: false
        },
        {
            title: 'Mexico',
            persianName: 'مکزیک',
            fidaId: 143,
            arabian: false
        },
        {
            title: 'Micronesia',
            persianName: 'میکرونزی',
            fidaId: 144,
            arabian: false
        },
        {
            title: 'Moldova',
            persianName: 'مالداوا',
            fidaId: 145,
            arabian: false
        },
        {
            title: 'Monaco',
            persianName: 'موناکو',
            fidaId: 146,
            arabian: false
        },
        {
            title: 'Mongolia',
            persianName: 'مغولستان',
            fidaId: 147,
            arabian: false
        },
        {
            title: 'Montserrat',
            persianName: 'مونتسرات',
            fidaId: 148,
            arabian: false
        },
        {
            title: 'Morocco',
            persianName: 'مغرب (مراکش)',
            fidaId: 149,
            arabian: true
        },
        {
            title: 'Mozambique',
            persianName: 'موزامبیک',
            fidaId: 150,
            arabian: false
        },
        {
            title: 'Myanmar',
            persianName: 'میانمار(برمه)',
            fidaId: 151,
            arabian: false
        },
        {
            title: 'Namibia',
            persianName: 'نامیبیا',
            fidaId: 152,
            arabian: false
        },
        {
            title: 'Nauru',
            persianName: 'نائورو',
            fidaId: 153,
            arabian: false
        },
        {
            title: 'Nepal',
            persianName: 'نپال',
            fidaId: 154,
            arabian: false
        },
        {
            title: 'Netherlands Antilles',
            persianName: 'جزایر انتیل هلند',
            fidaId: 155,
            arabian: false
        },
        {
            title: 'Netherlands',
            persianName: 'هلند',
            fidaId: 156,
            arabian: false
        },
        {
            title: 'New Caledonia',
            persianName: 'کالدونیای جدید',
            fidaId: 157,
            arabian: false
        },
        {
            title: 'New Zealand',
            persianName: 'نیوزلند(زلاند نو)',
            fidaId: 158,
            arabian: false
        },
        {
            title: 'Nicaragua',
            persianName: 'نیکاراگوا',
            fidaId: 159,
            arabian: false
        },
        {
            title: 'Niger',
            persianName: 'نیجر',
            fidaId: 160,
            arabian: false
        },
        {
            title: 'Nigeria',
            persianName: 'نیجریه',
            fidaId: 161,
            arabian: false
        },
        {
            title: 'Niue',
            persianName: 'نیووی',
            fidaId: 162,
            arabian: false
        },
        {
            title: 'Norfolk Island',
            persianName: 'جزیره نورفک',
            fidaId: 163,
            arabian: false
        },
        {
            title: 'North Korea',
            persianName: 'کره شمالی',
            fidaId: 164,
            arabian: false
        },
        {
            title: 'Northern Mariana Islands',
            persianName: 'جزایر ماریانای شمالی',
            fidaId: 165,
            arabian: false
        },
        {
            title: 'Norway',
            persianName: 'نروژ',
            fidaId: 166,
            arabian: false
        },
        {
            title: 'Oman',
            persianName: 'عمان',
            fidaId: 167,
            arabian: true
        },
        {
            title: 'Pakistan',
            persianName: 'پاکستان',
            fidaId: 168,
            arabian: false
        },
        {
            title: 'Palau',
            persianName: 'پالائو',
            fidaId: 169,
            arabian: false
        },
        {
            title: 'Panama',
            persianName: 'پاناما',
            fidaId: 170,
            arabian: false
        },
        {
            title: 'Papua New Guinea',
            persianName: 'پاپوا، گینه نو',
            fidaId: 171,
            arabian: false
        },
        {
            title: 'Paraguay',
            persianName: 'پاراگوئه ',
            fidaId: 172,
            arabian: false
        },
        {
            title: 'Peru',
            persianName: 'پرو',
            fidaId: 173,
            arabian: false
        },
        {
            title: 'Philippines',
            persianName: 'فیلیپین',
            fidaId: 174,
            arabian: false
        },
        {
            title: 'Pitcairn Islands',
            persianName: 'جزایر پیت‌کرن',
            fidaId: 175,
            arabian: false
        },
        {
            title: 'Poland',
            persianName: 'لهستان',
            fidaId: 176,
            arabian: false
        },
        {
            title: 'Portugal',
            persianName: 'پرتغال',
            fidaId: 177,
            arabian: false
        },
        {
            title: 'Puerto Rico',
            persianName: 'پورتوریکو',
            fidaId: 178,
            arabian: false
        },
        {
            title: 'Qatar',
            persianName: 'قطر',
            fidaId: 179,
            arabian: true
        },
        {
            title: 'Reunion',
            persianName: 'ریونیون',
            fidaId: 180,
            arabian: false
        },
        {
            title: 'Romania',
            persianName: 'رومانی',
            fidaId: 181,
            arabian: false
        },
        {
            title: 'Russia',
            persianName: 'روسیه(فدراتیو)',
            fidaId: 182,
            arabian: false
        },
        {
            title: 'Rwanda',
            persianName: 'رواندا',
            fidaId: 183,
            arabian: false
        },
        {
            title: 'Sao Tome and Principe',
            persianName: 'سائوتومه وپرنسیپ',
            fidaId: 184,
            arabian: false
        },
        {
            title: 'Samoa',
            persianName: 'ساموآ',
            fidaId: 185,
            arabian: false
        },
        {
            title: 'San Marino',
            persianName: 'سان مارینو',
            fidaId: 186,
            arabian: false
        },
        {
            title: 'Saudi Arabia',
            persianName: 'عربستان سعودی',
            fidaId: 187,
            arabian: true
        },
        {
            title: 'Senegal',
            persianName: 'سنگال',
            fidaId: 188,
            arabian: false
        },
        {
            title: 'Seychelles',
            persianName: 'سیشل',
            fidaId: 189,
            arabian: false
        },
        {
            title: 'Sierra Leone',
            persianName: 'سیرالئون ',
            fidaId: 190,
            arabian: false
        },
        {
            title: 'Singapore',
            persianName: 'سنگاپور',
            fidaId: 191,
            arabian: false
        },
        {
            title: 'Slovakia',
            persianName: 'اسلوواکی',
            fidaId: 192,
            arabian: false
        },
        {
            title: 'Slovenia',
            persianName: 'اسلوونی',
            fidaId: 193,
            arabian: false
        },
        {
            title: 'Solomon Islands',
            persianName: 'جزایرسلیمان',
            fidaId: 194,
            arabian: false
        },
        {
            title: 'Somalia',
            persianName: 'سومالی',
            fidaId: 195,
            arabian: true
        },
        {
            title: 'South Africa',
            persianName: 'آفریقای جنوبی',
            fidaId: 196,
            arabian: false
        },
        {
            title: 'South Georgia and the South Sandwich Islands',
            persianName: 'جزایر جورجیای جنوبی و ساندویچ جنوبی',
            fidaId: 197,
            arabian: false
        },
        {
            title: 'Spain',
            persianName: 'اسپانیا',
            fidaId: 198,
            arabian: false
        },
        {
            title: 'Sri Lanka',
            persianName: 'سریلانکا',
            fidaId: 199,
            arabian: false
        },
        {
            title: 'Saint Helena',
            persianName: 'سنت هلن',
            fidaId: 200,
            arabian: false
        },
        {
            title: 'Saint Kitts and Nevis',
            persianName: 'سنت کیتس و نویس ',
            fidaId: 201,
            arabian: false
        },
        {
            title: 'Saint Lucia',
            persianName: 'سنت لوسیا',
            fidaId: 202,
            arabian: false
        },
        {
            title: 'Saint Pierre and Miquelon',
            persianName: 'سنت پیئرو میگوئلون',
            fidaId: 203,
            arabian: false
        },
        {
            title: 'Saint Vincent and the Grenadines',
            persianName: 'سنت وین سنت وگرنادین ',
            fidaId: 204,
            arabian: false
        },
        {
            title: 'Sudan',
            persianName: 'سودان',
            fidaId: 205,
            arabian: true
        },
        {
            title: 'Suriname',
            persianName: 'سورینام',
            fidaId: 206,
            arabian: false
        },
        {
            title: 'Svalbard and Jan Mayen',
            persianName: 'سوالبارد و یان ماین',
            fidaId: 207,
            arabian: false
        },
        {
            title: 'Swaziland',
            persianName: 'سوازیلند',
            fidaId: 208,
            arabian: false
        },
        {
            title: 'Sweden',
            persianName: 'سوئد',
            fidaId: 209,
            arabian: false
        },
        {
            title: 'Switzerland',
            persianName: 'سوئیس',
            fidaId: 210,
            arabian: false
        },
        {
            title: 'Syria',
            persianName: 'سوریه',
            fidaId: 211,
            arabian: true
        },
        {
            title: 'Taiwan',
            persianName: 'تایوان',
            fidaId: 212,
            arabian: false
        },
        {
            title: 'Tajikistan',
            persianName: 'تاجیکستان',
            fidaId: 213,
            arabian: false
        },
        {
            title: 'Tanzania',
            persianName: 'تانزانیا',
            fidaId: 214,
            arabian: false
        },
        {
            title: 'Thailand',
            persianName: 'تایلند',
            fidaId: 215,
            arabian: false
        },
        {
            title: 'Togo',
            persianName: 'توگو',
            fidaId: 216,
            arabian: false
        },
        {
            title: 'Tokelau',
            persianName: 'توکلائو',
            fidaId: 217,
            arabian: false
        },
        {
            title: 'Tonga',
            persianName: 'تونگا',
            fidaId: 218,
            arabian: false
        },
        {
            title: 'Trinidad and Tobago',
            persianName: 'توباگو',
            fidaId: 219,
            arabian: false
        },
        {
            title: 'Tunisia',
            persianName: 'تونس',
            fidaId: 220,
            arabian: true
        },
        {
            title: 'Turkey',
            persianName: 'ترکیه',
            fidaId: 221,
            arabian: false
        },
        {
            title: 'Turkmenistan',
            persianName: 'ترکمنستان',
            fidaId: 222,
            arabian: false
        },
        {
            title: 'Turks and Caicos Islands',
            persianName: 'جزایر تورکس و کایکوس',
            fidaId: 223,
            arabian: false
        },
        {
            title: 'Tuvalu',
            persianName: 'تووالو',
            fidaId: 224,
            arabian: false
        },
        {
            title: 'U.S. Minor Outlying Islands',
            persianName: 'جزایر کوچک حاشیه ایالات متحده آمریکا',
            fidaId: 225,
            arabian: false
        },
        {
            title: 'Uganda',
            persianName: 'اوگاندا',
            fidaId: 226,
            arabian: false
        },
        {
            title: 'Ukraine',
            persianName: 'اوکراین',
            fidaId: 227,
            arabian: false
        },
        {
            title: 'United Arab Emirates',
            persianName: 'امارات عربی متحده',
            fidaId: 228,
            arabian: true
        },
        {
            title: 'United Kingdom',
            persianName: 'انگلستان(اسکاتلند، ویلز)',
            fidaId: 229,
            arabian: false
        },
        {
            title: 'United States',
            persianName: 'ایالات متحده آمریکا',
            fidaId: 230,
            arabian: false
        },
        {
            title: 'Uruguay',
            persianName: 'اروگوئه',
            fidaId: 231,
            arabian: false
        },
        {
            title: 'Uzbekistan',
            persianName: 'ازبکستان',
            fidaId: 232,
            arabian: false
        },
        {
            title: 'Vanuatu',
            persianName: 'وانواتو',
            fidaId: 233,
            arabian: false
        },
        {
            title: 'Vatican City',
            persianName: 'واتیکان',
            fidaId: 234,
            arabian: false
        },
        {
            title: 'Venezuela',
            persianName: 'ونزوئلا',
            fidaId: 235,
            arabian: false
        },
        {
            title: 'Viet Nam',
            persianName: 'ویتنام',
            fidaId: 236,
            arabian: false
        },
        {
            title: 'United States Virgin Islands',
            persianName: 'جزیره انگلیسی ویرجین',
            fidaId: 237,
            arabian: false
        },
        {
            title: 'British Virgin Islands',
            persianName: 'جزیره انگلیسی ویرجین',
            fidaId: 238,
            arabian: false
        },
        {
            title: 'Wallis and Futuna',
            persianName: 'والیس و فوتونا',
            fidaId: 239,
            arabian: false
        },
        {
            title: 'Yemen',
            persianName: 'یمن',
            fidaId: 240,
            arabian: true
        },
        {
            title: 'Yugoslavia',
            persianName: 'یوگسلاوی',
            fidaId: 241,
            arabian: false
        },
        {
            title: 'Zambia',
            persianName: 'زامبیا',
            fidaId: 242,
            arabian: false
        },
        {
            title: 'Zimbabwe',
            persianName: 'زیمبابوه',
            fidaId: 243,
            arabian: false
        },
        {
            title: 'Aland Islands',
            persianName: 'جزیره آلند',
            fidaId: 244,
            arabian: false
        },
        {
            title: 'Caribbean Netherlands',
            persianName: 'جزایر کارائیب هلند',
            fidaId: 245,
            arabian: false
        },
        {
            title: 'Curacao',
            persianName: 'کوراسائو',
            fidaId: 246,
            arabian: false
        },
        {
            title: 'Montenegro',
            persianName: 'مونته‌نگرو',
            fidaId: 247,
            arabian: false
        },
        {
            title: 'Serbia',
            persianName: 'صربستان',
            fidaId: 248,
            arabian: false
        },
        {
            title: 'Palestine',
            persianName: 'فلسطین',
            fidaId: 249,
            arabian: true
        },
        {
            title: 'Western Sahara',
            persianName: 'صحرای غربی',
            fidaId: 250,
            arabian: false
        },
        {
            title: 'Chechen',
            persianName: 'چچن اینگوش',
            fidaId: 253,
            arabian: false
        },
        {
            title: 'Kosovo',
            persianName: 'کوزوو',
            fidaId: 257,
            arabian: false
        },
        {
            title: 'Dagestan',
            persianName: 'داغستان',
            fidaId: 261,
            arabian: false
        }
    ];
    const [countryOptions, setCountryOptions] = useState([{
        value: null,
        label: '',
    }]);
    const [haveCountries, setHaveCountries] = useState(false);

    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        identificationNumber: '',
        TouristStatus: null,
        CardStatus: null,
        Pan: null,
        birthPlaceCountryId: null,
        nationalityId: null,
        creationDateStart: '',
        creationDateEnd: '',
        deliverCardPlace: null,
        Page: 1,
        Count: 100,
    });

    const [spareForm, setSpareForm] = useState({
        touristSt: null,
        cardSt: null,
        birthCountry: null,
        nationality: null,
        dateStart: null,
        dateEnd: null,
        place: null,
    });

    const {firstName, lastName, identificationNumber, TouristStatus,
        CardStatus, Pan, birthPlaceCountryId, nationalityId,
        creationDateStart, creationDateEnd, deliverCardPlace, Page, Count} = form;

    const {touristSt, cardSt, birthCountry, nationality, dateStart, dateEnd, place} = spareForm;

    const deliverPlaceOptions = [{ value: 1, label: 'سیمرغ تجارت' },{ value: 2, label: 'شعبه' }];

    const CardStatusOptions = [
        { value: 1, label: 'فعال' },
        { value: 2, label: 'ضبط شده' },
        { value: 3, label: 'مسدود' },
        { value: 4, label: 'غیرفعال' },
        { value: 5, label: 'غیرفعال اینترنتی' },
        { value: 6, label: 'باطل' },
        { value: 7, label: 'خارج از سرویس' },
        { value: 8, label: 'معلق' }
    ];

    const TouristStatusOptions = [
        { value: 1, label: 'ثبت درخواست' },
        { value: 2, label: 'درحال بررسی' },
        { value: 3, label: 'تایید شده' },
        { value: 4, label: 'رد شده' },
        { value: 5, label: 'عدم مغایرت' },
    ];

    const getToken = () => {
        setToken();
        setInterval(() => {
            setToken();
        }, 3000000); //50min
    };

    useEffect(() => {
        if (!haveRes && t) {
            auth();
            setHaveRes(true);
        }
        if (res === false) {
            history.push('/')
        } else {
            if (!haveToken) {
                getToken();
                setHaveToken(true);
            }
            if (tokenError) {
                setAlert(tokenError, 'danger');
                clearErrors();
            }
        }
        if (!haveCountries) {
            countries.map( c => countryOptions.push({ value: c.fidaId, label: c.title}));
            setHaveCountries(true);
        }
    }, [haveRes, res, haveToken, tokenError, haveCountries]);

    const onChange = e => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const TouristStatusChangeHandler = (status) => {
        setSpareForm({ ...spareForm, touristSt: status });
        setForm({ ...form, TouristStatus: status.value });
    };

    const CardStatusChangeHandler = (status) => {
        setSpareForm({ ...spareForm, cardSt: status });
        setForm({ ...form, CardStatus: status.value });
    };

    const birthCountryChangeHandler = (country) => {
        setSpareForm({ ...spareForm, birthCountry: country });
        setForm({ ...form, birthPlaceCountryId: country.value })
    };

    const nationalityChangeHandler = (country) => {
        setSpareForm({ ...spareForm, nationality: country });
        setForm({ ...form, nationalityId: country.value })
    };

    const creationDateStartChangeHandler = (date) => {
        setSpareForm({ ...spareForm, dateStart: date });
        setForm({ ...form, creationDateStart:
                moment(date, 'DD.MM.YYYY H:mm:ss').add(1,'day').toISOString()});
    };

    const creationDateEndChangeHandler = (date) => {
        setSpareForm({ ...spareForm, dateEnd: date });
        setForm({ ...form, creationDateEnd:
                moment(date, 'DD.MM.YYYY H:mm:ss').add(1,'day').toISOString()});
    };

    const deliverPlaceChangeHandler = (place) => {
        setSpareForm({ ...spareForm, place: place });
        setForm({ ...form, deliverCardPlace: place.value });
    };

    const [showDialogBox, setShowDialogBox] = useState(false);
    const [dialogBoxType, setDialogBoxType] = useState(null);
    const [dialogBoxData, setDialogBoxData] = useState({
        actionMessage: '',
        referenceNumber: null,
        traceNumber: null,
        touristId: null,
        error: ''
    });
    const [data, setData] = useState([]);

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoad(false);
        const url1 = `firstName=${firstName}&lastName=${lastName}&identificationNumber=${identificationNumber}`;
        const url2 = `&TouristStatus=${TouristStatus}&CardStatus=${CardStatus}&Pan=${Pan}`;
        const url3 = `&birthPlaceCountryId=${birthPlaceCountryId}&nationalityId=${nationalityId}`;
        const url4 = `&creationDateStart=${creationDateStart}&creationDateEnd=${creationDateEnd}`;
        const url5 = `&deliverCardPlace=${deliverCardPlace}`;
        const params = url1 + url2 + url3 + url4 + url5;

        const config = {
            headers: {
                Authorization: 'Bearer ' + bankToken,
                traceNumber: '12345',
                deviceId: 'IMEI'
            }
        };
        try {
            const res = await axios.get(`https://setplus.stts.ir/tcms/api/Tourist/GetByFilter?Page=${Page}&Count=${Count}&${params}`, config);
            setData((res.data.data).reverse());
            setShowDialogBox(false);
            setLoad(true);
        } catch (e) {
            console.log('Get Registered Tourists Error: ', e);
            setShowDialogBox(true);
            setDialogBoxType(false);
            setDialogBoxData({
                actionMessage: '',
                referenceNumber: null,
                traceNumber: null,
                touristId: null,
                error: e.toString(),
            });
            setData([]);
            setLoad(true);
        }
    };

    const showResult = () => {
        if (load === true) {
            return (
                <>
                    <div className='grid-4' style={styles.headerStyle}>
                        <div style={{paddingLeft: 5}}>Tourist ID</div>
                        <div style={{paddingLeft: 5}}>First Name</div>
                        <div style={{paddingLeft: 5}}>Last Name</div>
                        <div style={{paddingLeft: 5}}/>
                    </div>
                    {data.length !== 0
                        ? data.map(tourist => <TouristDetails2
                            key={tourist.touristId}
                            tourist={tourist}
                            bankToken={bankToken}
                        />)
                        : <label className='all-center' style={{color: 'red', margin: 15, marginBottom: 5}}>
                            There is no Tourist List!
                        </label>
                    }
                </>
            )
        } else if (load === false) {
            return <Spinner/>
        } else if (load === null) {
            return null;
        }
    };

    console.log(bankToken);
    return (
        <Fragment>
            <Sidebar defaultSelected='Registered-Tourists-List2'/>
            {load === false
                ? null
                : (
                    showDialogBox
                        ? <DialogBox data={dialogBoxData} type={dialogBoxType}/>
                        : null
                )
            }
            <div style={styles.backgroundStyle}>
                <div style={styles.titleStyle}>
                    <h2>Registered Tourists List</h2>
                    <h5>(In Bank System)</h5>
                </div>
                <Alerts/>
                <form style={styles.filterStyle} onSubmit={onSubmit}>
                    <div className='grid-2' style={{height:50, marginTop: -15}}>
                        <div style={{width:'20%', marginTop: 15}} >
                            <h3>Set Filters: </h3>
                        </div>
                        <div style={{marginLeft:'-80%', marginTop: 5}} className='text-right'>
                            <label htmlFor='Count'>Count: </label>
                            <input
                                style={{width: 70}}
                                type='number'
                                name='Count'
                                value={Count}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className='grid-4' style={styles.rowStyle}>
                        <div style={styles.colStyle}>
                            <label htmlFor='firstName'>First Name</label>
                            <input
                                type='text'
                                name='firstName'
                                value={firstName}
                                onChange={onChange}
                                maxLength='100'
                            />
                            <label htmlFor='CardStatus'>Card Status</label>
                            <Select
                                options={CardStatusOptions}
                                value={cardSt}
                                onChange={CardStatusChangeHandler}
                            />
                            <label htmlFor='creationDateStart'>Creation Date Start</label>
                            <br/>
                            <DatePicker
                                dateFormat='yyyy-MM-dd'
                                selected={dateStart}
                                onChange={creationDateStartChangeHandler}
                            />
                        </div>
                        <div style={styles.colStyle}>
                            <label htmlFor='lastName'>Last Name</label>
                            <input
                                type='text'
                                name='lastName'
                                value={lastName}
                                onChange={onChange}
                                maxLength='100'
                            />
                            <label htmlFor='Pan'>Pan {' '}
                                <label style={{fontSize: 12, color: 'gray'}}>
                                    ( Card Number )
                                </label>
                            </label>
                            <br/>
                            <input
                                type='number'
                                name='Pan'
                                value={Pan}
                                onChange={onChange}
                            />
                            <br/>
                            <label htmlFor='creationDateEnd'>Creation Date End</label>
                            <br/>
                            <DatePicker
                                dateFormat='yyyy-MM-dd'
                                selected={dateEnd}
                                onChange={creationDateEndChangeHandler}
                            />
                        </div>
                        <div style={styles.colStyle}>
                            <label htmlFor='identificationNumber'>Identification Number</label>
                            <input
                                type='text'
                                name='identificationNumber'
                                value={identificationNumber}
                                onChange={onChange}
                            />
                            <label htmlFor='birthPlaceCountryId'>Birth Place Country</label>
                            <Select
                                options={countryOptions}
                                value={birthCountry}
                                onChange={birthCountryChangeHandler}
                            />
                            <div style={{marginTop: -5}}>
                                <label htmlFor='deliverCardPlace'>Deliver Card Place</label>
                                <Select
                                    options={deliverPlaceOptions}
                                    value={place}
                                    onChange={deliverPlaceChangeHandler}
                                />
                            </div>
                        </div>
                        <div style={styles.colStyle}>
                            <label htmlFor='TouristStatus'>Tourist Status</label>
                            <Select
                                options={TouristStatusOptions}
                                value={touristSt}
                                onChange={TouristStatusChangeHandler}
                            />
                            <div style={{marginTop: -5}}>
                                <label htmlFor='nationalityId'>Nationality</label>
                                <Select
                                    options={countryOptions}
                                    value={nationality}
                                    onChange={nationalityChangeHandler}

                                />
                            </div>
                            <div className='text-right' style={{marginTop: 40}}>
                                <input
                                    style={styles.btnStyle}
                                    type='submit'
                                    value='Submit'
                                    className='btn'
                                />
                            </div>
                        </div>
                    </div>
                </form>
                <div style={styles.resultStyle}>
                    <h3>Results: </h3>
                    {showResult()}
                </div>
            </div>
        </Fragment>
    );
};

const styles = {
    backgroundStyle: {
        position: 'absolute',
        width: window.innerWidth - 65,
        marginLeft: 65,
    },
    titleStyle: {
        color: 'white',
        width: '90%',
        margin: 'auto',
        marginTop: 20,
        marginBottom: 0,
        paddingLeft: 10,
    },
    filterStyle: {
        backgroundColor: 'white',
        width: '90%',
        margin: 'auto',
        marginTop: 10,
        marginBottom: 10,
        padding: 30,
        paddingTop: 15,
        borderRadius: 10,
    },
    rowStyle: {
        marginTop: 10,
        borderStyle: 'solid',
        borderColor: 'gray',
        borderWidth: 2,
        borderRadius: 5,
    },
    colStyle: {
        padding: 15,
    },
    btnStyle: {
        backgroundColor: '#2c3c85',
        color: 'white',
        height: 35,
        width: 75,
        padding: 0,
        borderRadius: 5,
    },
    resultStyle: {
        backgroundColor: 'white',
        width: '90%',
        margin: 'auto',
        marginTop: 10,
        marginBottom: 50,
        padding: 30,
        paddingTop: 15,
        borderRadius: 10,
    },
    headerStyle: {
        marginTop: 10,
        paddingTop: 5,
        paddingBottom: 5,
        borderStyle: 'solid',
        borderColor: 'gray',
        borderWidth: 2,
    },
};

export default RegisteredTouristsList2;
