import React, {Fragment, useState} from "react";
import PropTypes from 'prop-types';
import axios from "axios";
import Spinner from "../Spinner";

const ChargeCard = ({ bankToken }) => {
    const [error, setError] = useState('');
    const [load, setLoad] = useState(null);

    const [form, setForm] = useState({
        pan: null,
        amount: null
    });
    const {pan, amount} = form;

    const [msg, setMsg] = useState({
        actionMessage: '',
        referenceNumber: null,
        traceNumber: null
    });

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoad(false);
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + bankToken,
                'traceNumber': '12345',
                'deviceId': 'IMEI'
            }
        };
        try {
            const res = await axios.post('https://setplus.stts.ir/tcms/api/Card/Charge', form, config);
            setMsg({
                actionMessage: res.data.actionMessage,
                referenceNumber: res.data.referenceNumber,
                traceNumber: res.data.traceNumber,
            });
            setError('');
            setLoad(true);
        } catch (e) {
            setMsg(null);
            setError(e.toString());
            console.log('Charge Card Error: ', e);
            setLoad(true);
        }
    };

    const onChange = e => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const showResult = () => {
        if (load === true && msg) {
            return (
                <div className='grid-2' style={{marginTop: 5, color: 'green'}}>
                    <div style={{width: '40%'}}>
                        <div><strong>Action Message:</strong></div>
                        <div><strong>Reference Number:</strong></div>
                        <div><strong>Trace Number:</strong></div>
                    </div>
                    <div style={{marginLeft: '-60%'}}>
                        <div>{msg.actionMessage}</div>
                        <div>{msg.referenceNumber}</div>
                        <div>{msg.traceNumber}</div>
                    </div>
                </div>
            )
        } else if (load === true && error) {
            return (
                <div style={{color: 'red', marginTop: 5}}>
                    {error}
                </div>
            )
        } else if (load === false) {
            return <Spinner/>
        } else if (load === null) {
            return null;
        } else {
            return null;
        }
    };

    return (
        <Fragment>
            <div style={styles.contentStyle}>
                <form onSubmit={onSubmit}>
                    <div className='grid-3'>
                        <div style={{width:'60%'}}>
                            <label htmlFor='pan'>Pan <span style={{color: 'red'}}>* </span></label>
                            <br/>
                            <input
                                type='number'
                                name='pan'
                                value={pan}
                                onChange={onChange}
                                required
                            />
                        </div>
                        <div style={{width:'60%', marginLeft: '-30%'}}>
                            <label htmlFor='amount'>Amount <span style={{color: 'red'}}>* </span></label>
                            <br/>
                            <input
                                type='number'
                                name='amount'
                                value={amount}
                                onChange={onChange}
                                required
                            />
                        </div>
                        <div style={{marginLeft: '-65%', paddingTop: 25}}>
                            <input
                                style={styles.submitBtnStyle}
                                type='submit'
                                value='Submit'
                                className='btn'
                            />
                        </div>
                    </div>
                </form>
            </div>
            <div style={styles.resultStyle}>
                <h3>Result: </h3>
                {showResult()}
            </div>
        </Fragment>
    )
};

ChargeCard.propTypes = {
    bankToken: PropTypes.string.isRequired,
};

const styles = {
    contentStyle: {
        margin: 15,
        marginLeft: 20,
        borderStyle: 'solid',
        borderWidth: 0,
        borderBottomWidth: 2
    },
    submitBtnStyle: {
        backgroundColor: '#2c3c85',
        color: 'white',
        height: 35,
        width: 75,
        padding: 0,
        borderRadius: 5,
    },
    resultStyle: {
        margin: 15,
        marginLeft: 20,
        paddingBottom: 10
    },
};

export default ChargeCard;
