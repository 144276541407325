import {SET_TOKEN, TOKEN_FAIL, CLEAR_ERRORS} from '../types';

export default (state, action) => {
    switch (action.type) {
        case SET_TOKEN:
            //localStorage.setItem('bankToken', action.payload);
            return {
                ...state,
                bankToken: action.payload,
                tokenError: ''
            };
        case TOKEN_FAIL:
            //localStorage.removeItem('bankToken');
            return {
                ...state,
                bankToken: '',
                tokenError: action.payload
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                tokenError: ''
            };
        default:
            return state;
    }
};
