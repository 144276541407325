import React, {useReducer} from 'react';
import axios from 'axios';
import AuthContext from './authContext';
import authReducer from './authReducer';
import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    CLEAR_ERRORS
} from '../types';
import history from '../../component/history';

const AuthState = props => {
    const initialState = {
        token: localStorage.getItem('token'),
        error: null,
    };
    const [state, dispatch] = useReducer(authReducer, initialState);

    //Login
    const login = async formData => {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        try {
            const res = await axios.post('https://admin.intravelcard.com/api/auth/admin-login/', formData, config);
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data
                // {'token':''}
            });
            history.push('/Registered-Tourists-List1');
        } catch (err) {
            dispatch({
                type: LOGIN_FAIL,
                payload: 'Invalid credentials!'
            });
        }
    };

    //Logout
    const logout = async () => {
        const token = localStorage.getItem('token');
        const config = {
            headers: {
                Authorization: `Token ${token}`
            }
        };
        try {
            await axios.get('https://admin.intravelcard.com/api/auth/logout/', config);
            dispatch({ type: LOGOUT });
            history.push('/');
        } catch (err) {
            dispatch({
                type: LOGIN_FAIL,
                payload: 'Error!'
            });
        }
    };

    //Clear Errors
    const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

    return (
        <AuthContext.Provider
            value={{
                token: state.token,
                error: state.error,
                login,
                logout,
                clearErrors,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
};

export default AuthState;
