import React, {Fragment, useContext, useEffect, useState} from 'react';
import axios from 'axios';
import '../Styles/App.css';
import TokenContext from '../../context/token/tokenContext';
import AlertContext from '../../context/alert/alertContext';
import Sidebar from '../layout/SideBar';
import Alerts from '../layout/Alerts';
import Spinner from '../layout/Spinner';
import history from "../history";

const TouristInquiry = () => {
    const t = localStorage.getItem('token');
    const [adminsID, setAdminsID] = useState([]);
    const [res, setRes] = useState(null);

    const auth = async () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        try {
            const admins = await axios.get('https://admin.intravelcard.com/api/auth/getAdmins/', config);
            admins.data.map(admin => adminsID.push(admin.id));
            const userId_token = await axios.get(`https://admin.intravelcard.com/api/auth/isAdmin/${t}`, config);
            if (userId_token.data[0]) {
                const TFList = adminsID.map(id => id === userId_token.data[0].user);
                if (TFList.includes(true)) {
                    setRes(true);
                } else {
                    setRes(false);
                    history.push('/')
                }
            } else {
                setRes(false);
            }
        } catch (err) {
            console.log('Error!')
        }
    };
    const [haveRes, setHaveRes] = useState(false);

    const tokenContext = useContext(TokenContext);
    const { bankToken, tokenError, setToken, clearErrors } = tokenContext;
    const [haveToken, setHaveToken] = useState(false);

    const alertContext = useContext(AlertContext);
    const { setAlert } = alertContext;

    const [error, setError] = useState('');
    const [load, setLoad] = useState(null);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [touristId, setTouristId] = useState(null);
    const [msg, setMsg] = useState({
        actionMessage: '',
        referenceNumber: null,
        traceNumber: null
    });
    const [data, setData] = useState({
        description: '',
        status: null
    });

    const getToken = () => {
        setToken();
        setInterval(() => {
            setToken();
        }, 3000000); //50min
    };

    const search = async (e) => {
        e.preventDefault();
        const config = {
            headers: {
                Authorization: 'Bearer ' + bankToken,
                traceNumber: '12345',
                deviceId: 'IMEI'
            }
        };
        const params = `firstName=${firstName}&lastName=${lastName}`;
        try {
            const res = await axios.get(`https://setplus.stts.ir/tcms/api/Tourist/GetByFilter?Page=1&Count=10&${params}`, config);
            setTouristId(res.data.data[res.data.data.length-1].touristId);
            setError('');
            setMsg(null);
            setData(null);
        } catch (e) {
            setTouristId(0);
            setError(e.toString());
            console.log('Get Tourist ID Error: ', e);
            setMsg(null);
            setData(null);
            setLoad(true);
        }
    };

    const getStatus = async (e) => {
        e.preventDefault();
        setLoad(false);
        const config = {
            headers: {
                Authorization: 'Bearer ' + bankToken,
                traceNumber: '12345',
                deviceId: 'IMEI'
            }
        };
        try {
            const res = await axios.get(`https://setplus.stts.ir/tcms/api/Tourist/GetStatus?id=${touristId}`, config);
            setMsg({
                actionMessage: res.data.actionMessage,
                referenceNumber: res.data.referenceNumber,
                traceNumber: res.data.traceNumber,
            });
            setData(res.data.data);
            setError('');
            setLoad(true);
        } catch (e) {
            console.log('Get Status Tourists Error: ', e);
            setError(e.toString());
            setMsg(null);
            setData(null);
            setLoad(true);
        }
    };

    useEffect(() => {
        if (!haveRes && t) {
            auth();
            setHaveRes(true);
        }
        if (res === false) {
            history.push('/')
        } else {
            if (!haveToken) {
                getToken();
                setHaveToken(true);
            }
            if (tokenError) {
                setAlert(tokenError, 'danger');
                clearErrors();
            }
        }
    }, [haveRes, res, haveToken, tokenError]);

    const onChange1 = e => {
        setTouristId(e.target.value)
    };
    const onChange2 = e => {
        setFirstName(e.target.value)
    };
    const onChange3 = e => {
        setLastName(e.target.value)
    };

    const statusOptions = ['ثبت درخواست', 'درحال بررسی', 'تایید شده', 'رد شده', 'عدم مغایرت'];

    const showResult = () => {
        if (load === true && data && msg) {
            return (
                <div className='grid-2' style={{marginTop: 5, color: 'green'}}>
                    <div style={{width: '40%'}}>
                        <div><strong>Action Message:</strong></div>
                        <div><strong>Reference Number:</strong></div>
                        <div><strong>Trace Number:</strong></div>
                        <div><strong>Status:</strong></div>
                        <div><strong>Description:</strong></div>
                    </div>
                    <div style={{marginLeft: '-60%'}}>
                        <div>{msg.actionMessage}</div>
                        <div>{msg.referenceNumber}</div>
                        <div>{msg.traceNumber}</div>
                        <div>{statusOptions[data.status-1]}</div>
                        <div>{data.description}</div>
                    </div>
                </div>
            )
        } else if (load === true && error) {
            return (
                <div style={{color: 'red', marginTop: 5}}>
                    {error}
                </div>
            )
        } else if (load === false) {
            return <Spinner/>
        } else if (load === null) {
            return null;
        } else {
            return null;
        }
    };

    console.log(bankToken);
    return (
        <Fragment>
            <Sidebar defaultSelected='Tourist-Inquiry'/>
            <div style={styles.backgroundStyle}>
                <div style={styles.containerStyle}>
                    <h2>Tourist Status Inquiry</h2>
                    <h5>(In Bank System)</h5>
                    <Alerts/>
                    <form className='grid-3' onSubmit={search} style={{marginTop: 15}}>
                        <div style={{width:'60%'}}>
                            <label htmlFor='firstName'>First Name </label>
                            <input
                                type='text'
                                name='firstName'
                                value={firstName}
                                onChange={onChange2}
                                required
                            />
                        </div>
                        <div style={{width:'60%', marginLeft: '-30%'}}>
                            <label htmlFor='lastName'>Last Name </label>
                            <input
                                type='text'
                                name='lastName'
                                value={lastName}
                                onChange={onChange3}
                                required
                            />
                        </div>
                        <div style={{marginLeft: '-60%'}}>
                            <input
                                style={styles.searchBtnStyle}
                                type='submit'
                                value='Search'
                                className='btn'
                            />
                        </div>
                    </form>
                    <form onSubmit={getStatus}>
                        <label htmlFor='touristId'>Tourist ID </label>
                        <br/>
                        <input
                            type='number'
                            name='touristId'
                            value={touristId}
                            onChange={onChange1}
                            required
                        />
                        <input
                            style={styles.submitBtnStyle}
                            type='submit'
                            value='Submit'
                            className='btn'
                        />
                    </form>
                </div>
                <div style={styles.resultStyle}>
                    <h3>Result: </h3>
                    {showResult()}
                </div>
            </div>
        </Fragment>
    );
};

const styles = {
    backgroundStyle: {
        position: 'absolute',
        width: window.innerWidth - 65,
        marginLeft: 65,
    },
    containerStyle: {
        backgroundColor: 'white',
        width: '90%',
        margin: 'auto',
        marginTop: 50,
        marginBottom: 10,
        padding: 30,
        paddingTop: 15,
        paddingBottom: 15,
        borderRadius: 10,
    },
    submitBtnStyle: {
        backgroundColor: '#2c3c85',
        color: 'white',
        height: 35,
        width: 75,
        padding: 0,
        marginLeft: '5%',
        borderRadius: 5,
    },
    searchBtnStyle: {
        position: 'relative',
        backgroundColor: '#2c3c85',
        color: 'white',
        height: 35,
        width: 75,
        padding: 0,
        top: 24,
        borderRadius: 5,
    },
    resultStyle: {
        backgroundColor: 'white',
        width: '90%',
        margin: 'auto',
        marginTop: 10,
        marginBottom: 50,
        padding: 30,
        paddingTop: 15,
        borderRadius: 10,
    },
};

export default TouristInquiry;
