import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Tabs} from '@feuer/react-tabs';
import Sidebar from '../layout/SideBar';
import TokenContext from '../../context/token/tokenContext';
import AlertContext from '../../context/alert/alertContext';
import CardIssuance from '../layout/Card/CardIssuance';
import CardInquiry from '../layout/Card/CardInquiry';
import CardBalance from '../layout/Card/CardBalance';
import CardTransactions from '../layout/Card/CardTransactions';
import ChargeCard from '../layout/Card/ChargeCard';
import Alerts from '../layout/Alerts';
import axios from "axios";
import history from "../history";

const CardServices = () => {
    const t = localStorage.getItem('token');
    const [adminsID, setAdminsID] = useState([]);
    const [res, setRes] = useState(null);

    const auth = async () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        try {
            const admins = await axios.get('https://admin.intravelcard.com/api/auth/getAdmins/', config);
            admins.data.map(admin => adminsID.push(admin.id));
            const userId_token = await axios.get(`https://admin.intravelcard.com/api/auth/isAdmin/${t}`, config);
            if (userId_token.data[0]) {
                const TFList = adminsID.map(id => id === userId_token.data[0].user);
                if (TFList.includes(true)) {
                    setRes(true);
                } else {
                    setRes(false);
                    history.push('/')
                }
            } else {
                setRes(false);
            }
        } catch (err) {
            console.log('Error!')
        }
    };
    const [haveRes, setHaveRes] = useState(false);

    const tokenContext = useContext(TokenContext);
    const { bankToken, tokenError, setToken, clearErrors } = tokenContext;
    const [haveToken, setHaveToken] = useState(false);

    const alertContext = useContext(AlertContext);
    const { setAlert } = alertContext;

    const getToken = () => {
        setToken();
        setInterval(() => {
            setToken();
        }, 3000000); //50min
    };

    useEffect(() => {
        if (!haveToken) {
            getToken();
            setHaveToken(true);
        }
        if (tokenError) {
            setAlert(tokenError, 'danger');
            clearErrors();
        }

    }, [haveToken, tokenError]);

    console.log('b', bankToken);

    return (
        <Fragment>
            <Sidebar defaultSelected='Card-Services'/>
            <div style={styles.backgroundStyle}>
                <div style={styles.containerStyle}>
                    <h2>Card Services</h2>
                    <Alerts/>
                    <div style={styles.tabsStyle}>
                        <Tabs
                            tabsProps={{style: {borderStyle: 'solid', borderWidth: 0, borderBottomWidth: 2}}}
                            activeTab={{id: 'tab1'}}
                        >
                            <Tabs.Tab id='tab1' title='Card Issuance'>
                                <CardIssuance bankToken={bankToken} />
                            </Tabs.Tab>
                            <Tabs.Tab id='tab2' title='Card Status Inquiry'>
                                <CardInquiry bankToken={bankToken} />
                            </Tabs.Tab>
                            <Tabs.Tab id='tab3' title='Card Balance'>
                                <CardBalance bankToken={bankToken} />
                            </Tabs.Tab>
                            <Tabs.Tab id='tab4' title='Report Card Transactions'>
                                <CardTransactions bankToken={bankToken} />
                            </Tabs.Tab>
                            <Tabs.Tab id='tab5' title='Charge the Card'>
                                <ChargeCard bankToken={bankToken} />
                            </Tabs.Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

const styles = {
    backgroundStyle: {
        position: 'absolute',
        width: window.innerWidth - 65,
        marginLeft: 65,
    },
    containerStyle: {
        backgroundColor: 'white',
        width: '90%',
        margin: 'auto',
        marginTop: 50,
        marginBottom: 50,
        padding: 30,
        paddingTop: 15,
        borderRadius: 10,
    },
    tabsStyle: {
        marginTop: 10,
        borderStyle: 'solid',
        borderWidth: 2,
        borderRadius: 5,
    },
};

export default CardServices;
