import React, {Fragment, useContext, useEffect, useState} from 'react';
import axios from 'axios';
import AlertContext from '../../context/alert/alertContext';
import Sidebar from '../layout/SideBar';
import Spinner from '../layout/Spinner';
import Alerts from '../layout/Alerts';
import TopUpDetails from "../layout/TopUpDetails";
import '../Styles/App.css';

const TopUp = () => {
    const alertContext = useContext(AlertContext);
    const { setAlert } = alertContext;

    const [error, setError] = useState('');
    const [load, setLoad] = useState(null);

    const [requests, setRequests] = useState([]);
    const [haveRequests, setHaveRequests] = useState(false);

    const getTopUpReq = async (e) => {
        setLoad(false);
        const config = {
            headers: {
                'Authorization' : 'token ' + localStorage.getItem('token'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
        try {
            const res = await axios.get('https://admin.intravelcard.com/api/req/TopUpsList/', config);
            setRequests(res.data.reverse());
            setLoad(true);
        } catch (e) {
            setRequests([]);
            setLoad(true);
            setError('Server Error!');
        }
    };

    useEffect(() => {
        if (error) {
            setAlert(error, 'danger');
            setError('');
        }
        if (!haveRequests) {
            getTopUpReq();
            setHaveRequests(true);
        }
    }, [error, haveRequests]);

    const showResult = () => {
        if (load === true) {
            return (
                <>
                    <div className='grid-5' style={styles.headerStyle}>
                        <div style={{paddingLeft: 5}}>#ID</div>
                        <div style={{paddingLeft: 5}}>First Name</div>
                        <div style={{paddingLeft: 5}}>Last Name</div>
                        <div style={{paddingLeft: 5}}>Done</div>
                        <div style={{paddingLeft: 5}}/>
                    </div>
                    {requests.length !== 0
                        ? requests.map(req => <TopUpDetails key={req.id} req={req}/>)
                        : <label className='all-center' style={{color: 'red', margin: 15, marginBottom: -15}}>
                            There is no Top-up Request!
                        </label>
                    }
                </>
            )
        } else if (load === false) {
            return <Spinner/>
        } else if (load === null) {
            return null;
        } else {
            return null;
        }
    };

    return (
        <Fragment>
            <Sidebar defaultSelected='Top-Up-Requests'/>
            <div style={styles.backgroundStyle}>
                <div style={styles.containerStyle}>
                    <div className='grid-2'>
                        <div>
                            <h2>Top-up Requests</h2>
                        </div>
                        <div className='text-right' style={{marginTop: 15}}>
                            <a
                                className='btn text-center'
                                style={styles.djangoBtnStyle}
                                href='https://admin.intravelcard.com/admin/requests/topup/'
                                target='_blank'
                            >
                                Go to django panel admin
                            </a>
                        </div>
                    </div>
                    <Alerts/>
                    <div style={styles.resultStyle}>
                        {showResult()}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

const styles = {
    backgroundStyle: {
        position: 'absolute',
        width: window.innerWidth - 65,
        marginLeft: 65,
    },
    containerStyle: {
        backgroundColor: 'white',
        width: '90%',
        margin: 'auto',
        marginTop: 50,
        marginBottom: 50,
        padding: 30,
        paddingTop: 15,
        borderRadius: 10,
    },
    djangoBtnStyle: {
        backgroundColor: '#2c3c85',
        color: 'white',
        height: 35,
        width: '40%',
        padding: 0,
        paddingTop: 4,
        borderRadius: 5,
    },
    resultStyle: {
        backgroundColor: 'white',
        width: '100%',
        margin: 'auto',
        marginTop: 10,
        marginBottom: 10,
    },
    headerStyle: {
        marginTop: 10,
        paddingTop: 5,
        paddingBottom: 5,
        borderStyle: 'solid',
        borderColor: 'gray',
        borderWidth: 2,
    },
};

export default TopUp;
