import React, {Fragment, useContext} from 'react';
import SideNav, {Toggle, Nav, NavItem, NavIcon, NavText} from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import AuthContext from '../../context/auth/authContext';
import PropTypes from 'prop-types';
import history from '../history';
import '../Styles/App.css';

const Sidebar = ({defaultSelected}) => {
    const authContext = useContext(AuthContext);
    const { logout } = authContext;

    return (
        <Fragment>
            <SideNav id='nav'
                onSelect={(eventKey) => {
                    if (eventKey === 'logout') {
                        logout();
                    } else {
                        history.push(`/${eventKey}`)
                    }
                }}
                style={styles.sideNavStyle}
            >
                <Toggle id='toggle' />
                <Nav defaultSelected={defaultSelected}>
                    <NavItem eventKey='Registered-Tourists-List1'>
                        <NavIcon>
                            <i className='fa fa-list-ul' style={styles.iconStyle} />
                        </NavIcon>
                        <NavText style={styles.textStyle}>Registered Orders</NavText>
                    </NavItem>
                    <NavItem eventKey='Registered-Tourists-List2'>
                        <NavIcon>
                            <i className='fa fa-users' style={styles.iconStyle} />
                            <i className="fa fa-check" style={styles.checkIconStyle} />
                        </NavIcon>
                        <NavText style={styles.textStyle}>Registered Tourists (Bank)</NavText>
                    </NavItem>
                    <NavItem eventKey='Tourist-Inquiry'>
                        <NavIcon>
                            <i className="fa fa-user" style={styles.userIconStyle} />
                            <i className="fa fa-search" style={styles.iconStyle} />
                        </NavIcon>
                        <NavText style={styles.textStyle}>Tourist Status Inquiry</NavText>
                    </NavItem>
                    <NavItem eventKey='Card-Services'>
                        <NavIcon>
                            <i className='fa fa-credit-card' style={styles.iconStyle} />
                        </NavIcon>
                        <NavText style={styles.textStyle}>Card Services</NavText>
                    </NavItem>
                    <NavItem eventKey='Factors-List'>
                        <NavIcon>
                            <i className='fa fa-file-text-o' style={styles.iconStyle} />
                        </NavIcon>
                        <NavText style={styles.textStyle}>Factors</NavText>
                    </NavItem>
                    <NavItem eventKey='Refund-Requests'>
                        <NavIcon>
                            <i className='fa fa-credit-card' style={styles.iconStyle} />
                            <i className="fa fa-usd" style={styles.refundIconStyle} />
                        </NavIcon>
                        <NavText style={styles.textStyle}>Refund Requests</NavText>
                    </NavItem>
                    <NavItem eventKey='Lost-Requests'>
                        <NavIcon>
                            <i className='fa fa-credit-card' style={styles.iconStyle} />
                            <i className="fa fa-times" style={styles.lostIconStyle} />
                        </NavIcon>
                        <NavText style={styles.textStyle}>Lost Requests</NavText>
                    </NavItem>
                    <NavItem eventKey='Top-Up-Requests'>
                        <NavIcon>
                            <i className='fa fa-credit-card' style={styles.iconStyle} />
                            <i className="fa fa-plus" style={styles.topupIconStyle} />
                        </NavIcon>
                        <NavText style={styles.textStyle}>Top-up Requests</NavText>
                    </NavItem>
                    <NavItem eventKey='logout'>
                        <NavIcon>
                            <i className='fa fa-sign-out' style={styles.logoutIconStyle} />
                        </NavIcon>
                        <NavText style={styles.textStyle}>Log Out</NavText>
                    </NavItem>
                </Nav>
            </SideNav>
        </Fragment>
    )
};

Sidebar.prototype = {
    defaultSelected: PropTypes.string.isRequired,
};

const styles = {
    sideNavStyle: {
        position: 'fixed',
        height: '100%',
        backgroundColor: '#006e97'
    },
    textStyle: {
        fontSize: 15,
        color: 'white',
    },
    iconStyle: {
        fontSize: 18,
        color: 'white',
    },
    checkIconStyle: {
        fontSize: 10,
        color: '#75dc75',
    },
    userIconStyle: {
        fontSize: 14,
        color: 'white',
    },
    refundIconStyle: {
        position: 'relative',
        top: 5,
        left: -2,
        fontSize: 14,
        color: '#75dc75',
    },
    lostIconStyle: {
        position: 'relative',
        top: 6,
        left: -3,
        fontSize: 14,
        color: 'red',
    },
    topupIconStyle: {
        position: 'relative',
        top: 5,
        left: -3,
        fontSize: 14,
        color: '#75dc75',
    },
    logoutIconStyle: {
        fontSize: 21,
        color: 'white',
    }
};

export default Sidebar;
