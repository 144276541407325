import React, {Fragment, useState} from "react";
import PropTypes from 'prop-types';
import axios from "axios";
import Spinner from "../Spinner";

const CardBalance = ({ bankToken }) => {
    const [error, setError] = useState('');
    const [load, setLoad] = useState(null);

    const [pan, setPan] = useState(null);
    const [msg, setMsg] = useState({
        actionMessage: '',
        referenceNumber: null,
        traceNumber: null
    });
    const [data, setData] = useState({
        balance: null
    });

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoad(false);
        const config = {
            headers: {
                'Authorization': 'Bearer ' + bankToken,
                'traceNumber': '12345',
                'deviceId': 'IMEI'
            }
        };
        try {
            const res = await axios.get(`https://setplus.stts.ir/tcms/api/Card/Balance?Pan=${Number(pan)}`, config);
            setMsg({
                actionMessage: res.data.actionMessage,
                referenceNumber: res.data.referenceNumber,
                traceNumber: res.data.traceNumber,
            });
            setData(res.data.data);
            setError('');
            setLoad(true);
        } catch (e) {
            setMsg(null);
            setData(null);
            setError(e.toString());
            console.log('Get Balance Card Error: ', e);
            setLoad(true);
        }
    };

    const onChange = e => {
        setPan(e.target.value)
    };

    const showResult = () => {
        if (load === true && msg && data) {
            return (
                <div className='grid-2' style={{marginTop: 5, color: 'green'}}>
                    <div style={{width: '40%'}}>
                        <div><strong>Action Message:</strong></div>
                        <div><strong>Reference Number:</strong></div>
                        <div><strong>Trace Number:</strong></div>
                        <div><strong>Balance:</strong></div>
                    </div>
                    <div style={{marginLeft: '-60%'}}>
                        <div>{msg.actionMessage}</div>
                        <div>{msg.referenceNumber}</div>
                        <div>{msg.traceNumber}</div>
                        <div>{data.balance}</div>
                    </div>
                </div>
            )
        } else if (load === true && error) {
            return (
                <div style={{color: 'red', marginTop: 5}}>
                    {error}
                </div>
            )
        } else if (load === false) {
            return <Spinner/>
        } else if (load === null) {
            return null;
        } else {
            return null;
        }
    };

    return (
        <Fragment>
            <div style={styles.contentStyle}>
                <form onSubmit={onSubmit}>
                    <label htmlFor='pan'>Pan </label>
                    <br/>
                    <input
                        type='number'
                        name='pan'
                        value={pan}
                        onChange={onChange}
                        required
                    />
                    <input
                        style={styles.submitBtnStyle}
                        type='submit'
                        value='Submit'
                        className='btn'
                    />
                </form>
            </div>
            <div style={styles.resultStyle}>
                <h3>Result: </h3>
                {showResult()}
            </div>
        </Fragment>
    )
};

CardBalance.propTypes = {
    bankToken: PropTypes.string.isRequired,
};

const styles = {
    contentStyle: {
        margin: 15,
        marginLeft: 20,
        borderStyle: 'solid',
        borderWidth: 0,
        borderBottomWidth: 2
    },
    submitBtnStyle: {
        backgroundColor: '#2c3c85',
        color: 'white',
        height: 35,
        width: 75,
        padding: 0,
        marginLeft: '5%',
        borderRadius: 5,
    },
    resultStyle: {
        margin: 15,
        marginLeft: 20,
        paddingBottom: 10
    }
};

export default CardBalance;
