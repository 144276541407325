import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Tabs} from '@feuer/react-tabs';
import axios from 'axios';
import '../Styles/App.css';
import TokenContext from '../../context/token/tokenContext';
import AlertContext from '../../context/alert/alertContext';
import Sidebar from '../layout/SideBar';
import Spinner from '../layout/Spinner';
import Alerts from '../layout/Alerts';
import TouristDetails1 from '../layout/TouristDetails1';

const RegisteredTouristsList1 = () => {
    const tokenContext = useContext(TokenContext);
    const { bankToken, tokenError, setToken, clearErrors } = tokenContext;
    const [haveToken, setHaveToken] = useState(false);

    const alertContext = useContext(AlertContext);
    const { setAlert } = alertContext;

    const [load, setLoad] = useState(null);
    const [error, setError] = useState('');

    const [tourists1, setTourists1] = useState([]);
    const [tourists2, setTourists2] = useState([]);
    const [tourists3, setTourists3] = useState([]);
    const [haveTourist, setHaveTourist] = useState(false);

    const getToken = () => {
        setToken();
        setInterval(() => {
            setToken();
        }, 3000000); //50min
    };

    const getTourists = async () => {
        setLoad(false);
        const config = {
            headers: {
                'Authorization' : 'token ' + localStorage.getItem('token'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
        try {
            const res1 = await axios.get('https://admin.intravelcard.com/api/req/TouristsList/1', config);
            const res2 = await axios.get('https://admin.intravelcard.com/api/req/TouristsList/2', config);
            const res3 = await axios.get('https://admin.intravelcard.com/api/req/TouristsList/3', config);
            setTourists1(res1.data.reverse());
            setTourists2(res2.data.reverse());
            setTourists3(res3.data.reverse());
            setLoad(true);
        } catch (e) {
            setTourists1([]);
            setTourists2([]);
            setTourists3([]);
            setLoad(true);
            setError('Server Error!');
        }
    };

    useEffect(() => {
        if (error) {
            setAlert(error, 'danger');
            setError('');
        }
        if (tokenError) {
            setAlert(tokenError, 'danger');
            clearErrors();
        }
        if (!haveTourist) {
            getTourists();
            if (tourists1.length !== 0 || tourists2.length !== 0 || tourists3.length !== 0) {
                if (!haveToken) {
                    getToken();
                    setHaveToken(true);
                }
            }
            setHaveTourist(true);
        }
    },[haveToken, error, tokenError, haveTourist, tourists1, tourists2, tourists3]);

    const showResult = (type) => {
        if (load === true) {
            return (
                <>
                    <div className='grid-5' style={styles.headerStyle}>
                        <div style={{paddingLeft: 5}}>#ID</div>
                        <div style={{paddingLeft: 5}}>First Name</div>
                        <div style={{paddingLeft: 5}}>Last Name</div>
                        <div style={{paddingLeft: 5}}>Confirmed</div>
                        <div style={{paddingLeft: 5}}/>
                    </div>
                    {type === 1
                        ? (
                            tourists1.length !== 0
                                ? tourists1.map(tourist =>
                                    <TouristDetails1
                                        key={tourist.id}
                                        tourist={tourist}
                                        bankToken={bankToken}
                                    />)
                                : <label className='all-center' style={{color: 'red', margin: 15, marginBottom: 5}}>
                                    There is no Tourist List!
                                </label>
                        )
                        : type === 2
                            ? (
                                tourists2.length !== 0
                                    ? tourists2.map(tourist =>
                                        <TouristDetails1
                                            key={tourist.id}
                                            tourist={tourist}
                                            bankToken={bankToken}
                                        />)
                                    : <label className='all-center' style={{color: 'red', margin: 15, marginBottom: 5}}>
                                        There is no Tourist List!
                                    </label>
                            )
                            : (
                                tourists3.length !== 0
                                    ? tourists3.map(tourist =>
                                        <TouristDetails1
                                            key={tourist.id}
                                            tourist={tourist}
                                            bankToken={bankToken}
                                        />)
                                    : <label className='all-center' style={{color: 'red', margin: 15, marginBottom: 5}}>
                                        There is no Tourist List!
                                    </label>
                            )
                    }
                </>
            )
        } else if (load === false) {
            return <Spinner/>
        } else if (load === null) {
            return null;
        }
    };

    console.log(bankToken);
    return (
        <Fragment>
            <Sidebar defaultSelected='Registered-Tourists-List1'/>
            <div style={styles.backgroundStyle}>
                <div style={styles.containerStyle}>
                    <div className='grid-2'>
                        <div>
                            <h2>Registered Orders (Tourists) List</h2>
                            <h5>(In Our System)</h5>
                        </div>
                        <div className='text-right' style={{marginTop: 15}}>
                            <a
                                className='btn text-center'
                                style={styles.djangoBtnStyle}
                                href='https://admin.intravelcard.com/admin/requests/tourist/'
                                target='_blank'
                            >
                                Go to django panel admin
                            </a>
                        </div>
                    </div>
                    <Alerts/>
                    <div style={styles.tabsStyle}>
                        <Tabs
                            tabsProps={{style: {borderStyle: 'solid', borderWidth: 0, borderBottomWidth: 2}}}
                            activeTab={{id: 'tab1'}}
                        >
                            <Tabs.Tab id='tab1' title='Card & SIMCard Order'>
                                <div style={styles.contentStyle}>
                                    {showResult(1)}
                                </div>
                            </Tabs.Tab>

                            <Tabs.Tab id='tab2' title='Card Order'>
                                <div style={styles.contentStyle}>
                                    {showResult(2)}
                                </div>
                            </Tabs.Tab>
                            <Tabs.Tab id='tab3' title='SIMCard Order'>
                                <div style={styles.contentStyle}>
                                    {showResult(3)}
                                </div>
                            </Tabs.Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

const styles = {
    backgroundStyle: {
        position: 'absolute',
        width: window.innerWidth - 65,
        marginLeft: 65,
    },
    containerStyle: {
        backgroundColor: 'white',
        width: '90%',
        margin: 'auto',
        marginTop: 50,
        marginBottom: 50,
        padding: 30,
        paddingTop: 15,
        borderRadius: 10,
    },
    djangoBtnStyle: {
        backgroundColor: '#2c3c85',
        color: 'white',
        height: 35,
        width: '40%',
        padding: 0,
        paddingTop: 4,
        marginTop: 10,
        marginLeft: '5%',
        borderRadius: 5,
    },
    tabsStyle: {
        marginTop: 10,
        borderStyle: 'solid',
        borderWidth: 2,
        borderRadius: 5,
    },
    contentStyle: {
        padding: 10,
    },
    headerStyle: {
        paddingTop: 5,
        paddingBottom: 5,
        borderStyle: 'solid',
        borderColor: 'gray',
        borderWidth: 2,
    },
};

export default RegisteredTouristsList1;
