import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Tabs} from '@feuer/react-tabs';
import axios from 'axios';
import '../Styles/App.css';
import AlertContext from '../../context/alert/alertContext';
import Sidebar from '../layout/SideBar';
import Spinner from '../layout/Spinner';
import Alerts from '../layout/Alerts';
import FactorDetail from '../layout/FactorDetail';

const Factors = () => {
    const alertContext = useContext(AlertContext);
    const { setAlert } = alertContext;

    const [load, setLoad] = useState(null);
    const [error, setError] = useState('');

    const [factors, setFactors] = useState([]);
    const [haveFactors, setHaveFactors] = useState(false);

    const getFactors = async () => {
        setLoad(false);
        const config = {
            headers: {
                'Authorization' : 'token ' + localStorage.getItem('token'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
        try {
            const res = await axios.get('https://admin.intravelcard.com/api/req/FactorsList/', config);
            setFactors(res.data.reverse());
            setLoad(true);
        } catch (e) {
            setFactors([]);
            setLoad(true);
            setError('Server Error!');
        }
    };

    useEffect(() => {
        if (error) {
            setAlert(error, 'danger');
            setError('');
        }
        if (!haveFactors) {
            getFactors();
            setHaveFactors(true);
        }
    },[error, haveFactors]);

    const showResult_single = () => {
        if (load === true  && factors) {
            const single = factors.filter(factor => factor.family_package === false);
            return (
                <>
                    <div className='grid-6' style={styles.headerStyle}>
                        <div style={{paddingLeft: 5, width: '50%'}}>#ID</div>
                        <div style={{paddingLeft: 5, marginLeft: '-50%'}}>Factor Number</div>
                        <div style={{paddingLeft: 5}}>First Name</div>
                        <div style={{paddingLeft: 5}}>Last Name</div>
                        <div style={{paddingLeft: 5}}>status</div>
                        <div style={{paddingLeft: 5}}/>
                    </div>
                    {factors.length !== 0
                        ? single.map(factor => <FactorDetail key={factor.id} factor={factor}/>)
                        : <label className='all-center' style={{color: 'red', margin: 15, marginBottom: 5}}>
                            There is no Tourist List!
                        </label>
                    }
                </>
            )
        } else if (load === false) {
            return <Spinner/>
        } else if (load === null) {
            return null;
        } else {
            return null;
        }
    };

    const showResult_family = () => {
        if (load === true && factors) {
            const family = factors.filter(factor => factor.family_package === true);
            return (
                <>
                    <div className='grid-6' style={styles.headerStyle}>
                        <div style={{paddingLeft: 5, width: '50%'}}>#ID</div>
                        <div style={{paddingLeft: 5, marginLeft: '-50%'}}>Factor Number</div>
                        <div style={{paddingLeft: 5}}>First Name</div>
                        <div style={{paddingLeft: 5}}>Last Name</div>
                        <div style={{paddingLeft: 5}}>status</div>
                        <div style={{paddingLeft: 5}}/>
                    </div>
                    {factors.length !== 0
                        ? family.map(factor => <FactorDetail key={factor.id} factor={factor}/>)
                        : <label className='all-center' style={{color: 'red', margin: 15, marginBottom: 5}}>
                            There is no Tourist List!
                        </label>
                    }
                </>
            )
        } else if (load === false) {
            return <Spinner/>
        } else if (load === null) {
            return null;
        } else {
            return null;
        }
    };

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [user_ID, setUser_ID] = useState(null);
    const [label, setLabel] = useState('');

    const onChange1 = e => {
        setUser_ID(e.target.value)
    };
    const onChange2 = e => {
        setFirstName(e.target.value)
    };
    const onChange3 = e => {
        setLastName(e.target.value)
    };

    const searchName = async (e) => {
        e.preventDefault();
        setLoad(false);
        const config = {
            headers: {
                'Authorization' : 'token ' + localStorage.getItem('token'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
        try {
            const res = await axios.get(`https://admin.intravelcard.com/api/req/FactorsList-Name/${firstName}&${lastName}`, config);
            if (res.data.length === 0) {
                setError('Not Found!');
                setFactors([]);
                setUser_ID(0);
                setLabel('');
                setLoad(true);
            } else {
                setFactors(res.data.reverse());
                setUser_ID(res.data[res.data.length-1].user_ID);
                setLoad(true);
                if (res.data[res.data.length-1].family_package) {
                    setLabel('(In Family Package)')
                }
            }
        } catch (e) {
            setFactors([]);
            setLoad(true);
            setError('Server Error!');
        }
    };

    const searchID = async (e) => {
        e.preventDefault();
        setLoad(false);
        const config = {
            headers: {
                'Authorization' : 'token ' + localStorage.getItem('token'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
        try {
            const res = await axios.get(`https://admin.intravelcard.com/api/req/FactorsList-ID/${user_ID}`, config);
            if (res.data.length === 0) {
                setError('Not Found!');
                setFactors([]);
                setUser_ID(0);
                setLabel('');
                setLoad(true);
            } else {
                setFactors(res.data.reverse());
                setLoad(true);
            }
        } catch (e) {
            setFactors([]);
            setLoad(true);
            setError('Server Error!');
        }
    };

    return (
        <Fragment>
            <Sidebar defaultSelected='Factors-List'/>
            <div style={styles.backgroundStyle}>
                <div style={styles.titleStyle}>
                    <div className='grid-2'>
                        <div>
                            <h2>Factors List</h2>
                        </div>
                        <div className='text-right' style={{marginTop: 15}}>
                            <a
                                className='btn text-center'
                                style={styles.djangoBtnStyle}
                                href='https://admin.intravelcard.com/admin/requests/factor/'
                                target='_blank'
                            >
                                Go to django panel admin
                            </a>
                        </div>
                    </div>
                </div>
                <Alerts/>
                <div style={styles.filterStyle}>
                    <div style={{}} >
                        <h3>Search: </h3>
                    </div>
                    <form onSubmit={searchName} className='grid-3' style={{marginTop: 15}}>
                        <div style={{width:'60%'}}>
                            <label htmlFor='firstName'>First Name </label>
                            <input
                                type='text'
                                name='firstName'
                                value={firstName}
                                onChange={onChange2}
                                required
                            />
                        </div>
                        <div style={{width:'60%', marginLeft: '-30%'}}>
                            <label htmlFor='lastName'>Last Name </label>
                            <input
                                type='text'
                                name='lastName'
                                value={lastName}
                                onChange={onChange3}
                                required
                            />
                        </div>
                        <div style={{marginLeft: '-60%'}}>
                            <input
                                style={styles.searchBtnStyle}
                                type='submit'
                                value='Search'
                                className='btn'
                            />
                            <label style={{position: 'relative', top: 24, color: 'green', left: 15}}>
                                {label}
                            </label>

                        </div>
                    </form>
                    <form onSubmit={searchID}>
                        <label htmlFor='user_ID'>User ID </label>
                        <br/>
                        <input
                            type='number'
                            name='user_ID'
                            value={user_ID}
                            onChange={onChange1}
                            required
                        />
                        <input
                            style={styles.submitBtnStyle}
                            type='submit'
                            value='Search'
                            className='btn'
                        />
                    </form>
                </div>
                <div style={styles.containerStyle}>
                    <div style={styles.tabsStyle}>
                        <Tabs
                            tabsProps={{style: {borderStyle: 'solid', borderWidth: 0, borderBottomWidth: 2}}}
                            activeTab={{id: 'tab1'}}
                        >
                            <Tabs.Tab id='tab1' title='Single Orders'>
                                <div style={styles.contentStyle}>
                                    {showResult_single()}
                                </div>
                            </Tabs.Tab>

                            <Tabs.Tab id='tab2' title='Family Package Orders'>
                                <div style={styles.contentStyle}>
                                    {showResult_family()}
                                </div>
                            </Tabs.Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

const styles = {
    backgroundStyle: {
        position: 'absolute',
        width: window.innerWidth - 65,
        marginLeft: 65,
    },
    titleStyle: {
        color: 'white',
        width: '90%',
        margin: 'auto',
        marginTop: 20,
        marginBottom: 0,
        paddingLeft: 10,
    },
    djangoBtnStyle: {
        backgroundColor: '#2c3c85',
        color: 'white',
        height: 35,
        width: '40%',
        padding: 0,
        paddingTop: 4,
        borderRadius: 5,
    },
    filterStyle: {
        backgroundColor: 'white',
        width: '90%',
        margin: 'auto',
        marginTop: 10,
        marginBottom: 10,
        padding: 30,
        paddingTop: 15,
        borderRadius: 10,
    },
    searchBtnStyle: {
        position: 'relative',
        backgroundColor: '#2c3c85',
        color: 'white',
        height: 35,
        width: 75,
        padding: 0,
        top: 24,
        borderRadius: 5,
    },
    submitBtnStyle: {
        backgroundColor: '#2c3c85',
        color: 'white',
        height: 35,
        width: 75,
        padding: 0,
        marginLeft: '5%',
        borderRadius: 5,
    },
    containerStyle: {
        backgroundColor: 'white',
        width: '90%',
        margin: 'auto',
        marginTop: 10,
        marginBottom: 50,
        padding: 30,
        paddingTop: 15,
        borderRadius: 10,
    },
    tabsStyle: {
        marginTop: 10,
        borderStyle: 'solid',
        borderWidth: 2,
        borderRadius: 5,
    },
    contentStyle: {
        padding: 10
    },
    headerStyle: {
        paddingTop: 5,
        paddingBottom: 5,
        borderStyle: 'solid',
        borderColor: 'gray',
        borderWidth: 2,
    },
};

export default Factors;
