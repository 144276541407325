import React, {Fragment, useState} from "react";
import PropTypes from 'prop-types';
import axios from "axios";
import DatePicker from "react-datepicker";
import moment from "moment";
import Select from "react-select";
import Spinner from "../Spinner";

const CardTransactions = ({ bankToken }) => {
    const [error, setError] = useState('');
    const [load, setLoad] = useState(null);

    const [form, setForm] = useState({
        pan: null,
        fromDate: '',
        toDate: '',
        paymentId: null,
        count: null,
        transactionType: null,
    });

    const [spareForm, setSpareForm] = useState({
        date1: '',
        date2: '',
        type: '',
    });

    const {pan, fromDate, toDate, paymentId, count, transactionType} = form;
    const {date1, date2, type} = spareForm;

    const [msg, setMsg] = useState({
        actionMessage: '',
        referenceNumber: null,
        traceNumber: null
    });
    const [data, setData] = useState([]);

    const transactionTypeOptions = [{ value: 0, label: 'برداشت' },{ value: 2, label: 'واریز' }];

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoad(false);
        const params = `pan=${Number(pan)}&fromdate=${fromDate}&todate=${toDate}&paymentId=${paymentId}&Count=${count}&TransactionType=${transactionType}`;
        const config = {
            headers: {
                'Authorization': 'Bearer ' + bankToken,
                'traceNumber': '12345',
                'deviceId': 'IMEI'
            }
        };
        try {
            const res = await axios.get(`https://setplus.stts.ir/tcms/api/Card/ReportTransaction?${params}`, config);
            setMsg({
                actionMessage: res.data.actionMessage,
                referenceNumber: res.data.referenceNumber,
                traceNumber: res.data.traceNumber,
            });
            setData(res.data.data);
            setError('');
            setLoad(true);
        } catch (e) {
            setMsg(null);
            setData(null);
            setError(e.toString());
            console.log('Report Card Transactions Error: ', e);
            setLoad(true);
        }
    };

    const onChange = e => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const fromDateChangeHandler = (date) => {
        setSpareForm({ ...spareForm, date1: date });
        setForm({ ...form, fromDate:
                moment(date, 'DD.MM.YYYY H:mm:ss').add(1,'day').toISOString()});
    };

    const toDateChangeHandler = (date) => {
        setSpareForm({ ...spareForm, date2: date });
        setForm({ ...form, toDate:
                moment(date, 'DD.MM.YYYY H:mm:ss').add(1,'day').toISOString()});
    };

    const transactionTypeChangeHandler = (type) => {
        setSpareForm({ ...spareForm, type: type });
        setForm({ ...form, transactionType: type.value })
    };

    const showResult = () => {
        if (load === true && msg && data) {
            return (
                <>
                    <div className='grid-2' style={{marginTop: 5, color: 'green'}}>
                        <div style={{width: '40%'}}>
                            <div><strong>Action Message:</strong></div>
                            <div><strong>Reference Number:</strong></div>
                            <div><strong>Trace Number:</strong></div>
                        </div>
                        <div style={{marginLeft: '-60%'}}>
                            <div>{msg.actionMessage}</div>
                            <div>{msg.referenceNumber}</div>
                            <div>{msg.traceNumber}</div>
                        </div>
                    </div>
                    {data.map(data => {
                        return (
                            <div className='grid-2' style={styles.dataStyle}>
                                <div style={{width: '55%'}}>
                                    <div><strong>Document Description:</strong></div>
                                    <div><strong>Payment ID:</strong></div>
                                    <div><strong>Transaction Document Number:</strong></div>
                                    <div><strong>Iisuer Branch Code:</strong></div>
                                    <div><strong>Transaction Type:</strong></div>
                                    <div><strong>Transaction Type Desc:</strong></div>
                                    <div><strong>Old Balance:</strong></div>
                                    <div><strong>Trans Amount:</strong></div>
                                    <div><strong>Trans Date:</strong></div>
                                </div>
                                <div style={{marginLeft: '-45%'}}>
                                    <div>{data.documentDescription}</div>
                                    <div>{data.paymentID}</div>
                                    <div>{data.transactionDocumentNumber}</div>
                                    <div>{data.IisuerBranchCode}</div>
                                    <div>{data.transactionType}</div>
                                    <div>{data.transactionTypeDesc}</div>
                                    <div>{data.oldBalance}</div>
                                    <div>{data.transAmount}</div>
                                    <div>{data.transDate}</div>
                                </div>
                            </div>
                        )
                    })}
                </>
            )
        } else if (load === true && error) {
            return (
                <div style={{color: 'red', marginTop: 5}}>
                    {error}
                </div>
            )
        } else if (load === false) {
            return <Spinner/>
        } else if (load === null) {
            return null;
        } else {
            return null;
        }
    };

    return (
        <Fragment>
            <div style={styles.contentStyle}>
                <form onSubmit={onSubmit}>
                    <div className='grid-4'>
                        <div>
                            <label htmlFor='pan'>Pan <span style={{color: 'red'}}>*</span></label>
                            <br/>
                            <input
                                type='number'
                                name='pan'
                                value={pan}
                                onChange={onChange}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor='fromDate'>From Date <span style={{color: 'red'}}>*</span></label>
                            <br/>
                            <DatePicker
                                dateFormat='yyyy-MM-dd'
                                selected={date1}
                                onChange={fromDateChangeHandler}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor='toDate'>To Date <span style={{color: 'red'}}>*</span></label>
                            <br/>
                            <DatePicker
                                dateFormat='yyyy-MM-dd'
                                selected={date2}
                                onChange={toDateChangeHandler}
                                required
                            />
                        </div>
                    </div>
                    <div className='grid-4'>
                        <div>
                            <label htmlFor='paymentId'>Payment ID </label>
                            <input
                                type='text'
                                name='paymentId'
                                value={paymentId}
                                onChange={onChange}
                                style={{width: 200}}
                            />
                        </div>
                        <div>
                            <label htmlFor='count'>Count {' '}
                                <label style={{fontSize: 12, color: 'gray'}}>
                                    (Max: 150)
                                </label>

                            </label>
                            <br/>
                            <input
                                type='number'
                                name='count'
                                value={count}
                                onChange={onChange}
                                maxLength='150'
                            />
                        </div>
                        <div>
                            <label htmlFor='transactionType'>Transaction Type </label>
                            <Select
                                options={transactionTypeOptions}
                                value={type}
                                onChange={transactionTypeChangeHandler}
                            />
                        </div>
                        <div style={{paddingTop: 25}}>
                            <input
                                style={styles.submitBtnStyle}
                                type='submit'
                                value='Submit'
                                className='btn'
                            />
                        </div>
                    </div>
                </form>
            </div>
            <div style={styles.resultStyle}>
                <h3>Result: </h3>
                {showResult()}
            </div>
        </Fragment>
    )
};

CardTransactions.propTypes = {
    bankToken: PropTypes.string.isRequired,
};

const styles = {
    contentStyle: {
        margin: 15,
        marginLeft: 20,
        borderStyle: 'solid',
        borderWidth: 0,
        borderBottomWidth: 2
    },
    submitBtnStyle: {
        backgroundColor: '#2c3c85',
        color: 'white',
        height: 35,
        width: 75,
        padding: 0,
        marginLeft: '15%',
        borderRadius: 5,
    },
    resultStyle: {
        margin: 15,
        marginLeft: 20,
        paddingBottom: 10
    },
    dataStyle: {
        marginTop: 5,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 10,
        borderStyle: 'solid',
        borderWidth: 2,
        borderRadius: 5,
    }
};

export default CardTransactions;
