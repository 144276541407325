import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import '../Styles/DialogBox.css';
import '../Styles/App.css';

const DialogBox = ({ data, type }) => {
    const [showDialogBox, setShowDialogBox] = useState(true);
    const [id, setId] = useState('');
    const [icon, setIcon] = useState('');
    const [color, setColor] = useState('');
    const onClick = (e) => {
        setShowDialogBox(false)
    };

    useEffect(() => {
        if (type) {
            setIcon('fa fa-check-circle');
            setColor('green');
            setId('id1');
        } else {
            setIcon('fa fa-times-circle');
            setColor('red');
            setId('id2');
        }
    }, [type]);

    const showComponent = () => {
        if (showDialogBox) {
            return(
                <div className='popup all-center' style={{zIndex: 2}}>
                    <div className='popup_inner' id={id}>
                        <div className='container m-1' style={styles.containerStyle}>
                            <i className={icon} style={{fontSize: 60, color: color}}/>
                            <div style={styles.msgStyle} className={color}>
                                {type
                                    ? (
                                        <>
                                            Action Message: <br/>
                                            {data.actionMessage} <br/>
                                            Reference Number: <br/>
                                            {data.referenceNumber} <br/>
                                            Trace Number: <br/>
                                            {data.traceNumber} <br/>
                                            Tourist ID: {data.touristId}
                                        </>
                                    )
                                    : data.error
                                }

                            </div>
                            <button
                                className='btn'
                                style={styles.btnStyle}
                                onClick={onClick}
                            > Ok </button>
                        </div>
                    </div>
                </div>
            )
        }
    };

    return (
        <>
            {showComponent()}
        </>
    );
};

DialogBox.propTypes = {
    data: PropTypes.object.isRequired,
    type: PropTypes.bool.isRequired,
};

const styles = {
    containerStyle: {
        margin: '3%',
        padding: 10,
        borderRadius: 5,
    },
    msgStyle: {
        marginTop: 10,
        marginBottom: 15,
    },
    btnStyle: {
        position: 'relative',
        backgroundColor: '#2c3c85',
        color: 'white',
        height: 30,
        width: '30%',
        margin: 'auto',
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 0,
        paddingBottom: 0,
        borderRadius: 3
    }
};

export default DialogBox;
