import React, {useReducer} from 'react';
import axios from "axios";
import qs from "querystring";
import TokenContext from './tokenContext';
import tokenReducer from './tokenReducer';
import {SET_TOKEN, TOKEN_FAIL, CLEAR_ERRORS} from '../types';

const TokenState = props => {
    const initialState = {
        bankToken: '',
        tokenError: '',
    };

    const [state, dispatch] = useReducer(tokenReducer, initialState);

    const setToken = async () => {
        const data = {
            refresh_token: '_KZM5mXWg78Z0vxbxjs9PY36fzk_Szse4XF4qmtkGNs',
            grant_type: 'refresh_token',
            client_id: 'ORGAN',
            client_secret: 'OrganSecret'
        };

        const config = {
            method: 'POST',
            //mode: 'no-cors',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Access-Control-Allow-Origin': "*"
            },
            body: qs.stringify(data)
        };

        await fetch('https://setplus.stts.ir/token', config)
            .then((response) => response.json())
            .then((json) => {
                dispatch({
                    type: SET_TOKEN,
                    payload: json.access_token
                });
            })
            .catch(e => {
                dispatch({
                    type: TOKEN_FAIL,
                    payload: 'Receiving Token Error!'
                });
                console.log('Receiving Token Error: ', e)
            });
    };

    const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

    return (
        <TokenContext.Provider
            value={{
                bankToken: state.bankToken,
                tokenError: state.tokenError,
                setToken,
                clearErrors
            }}
        >
            {props.children}
        </TokenContext.Provider>
    )
};

export default TokenState;
