import React, {Fragment, useEffect, useState} from 'react';
import {Collapse} from 'react-collapse';
import Select from 'react-select';
import axios from 'axios';
import PropTypes from 'prop-types';
import SpinnerModal from './SpinnerModal';
import DialogBox from './DialogBox';
import '../Styles/App.css';

const TouristDetails1 = ({ tourist, bankToken }) => {
    const [load, setLoad] = useState(null);

    const { id, user_ID, tourist_ID, firstName, lastName, email, phone, genderId,
        birthPlaceCountryId, birthPlaceCountry, nationalityId, nationality, fatherName, birthDate,
        identificationNumber, identificationIssueDate, identificationExpirationDate, identificationPhoto,
        amount, currency, deliveryCity, deliveryAddr, arrivalDate,
        description, orderType, price, status, createDateTime } = tourist;

    const [detailOpen, setDetailOpen] = useState(false);
    const [icon, setIcon] = useState('fa fa-angle-down');

    useEffect(() => {
        if (detailOpen) {
            setIcon('fa fa-angle-up')
        } else {
            setIcon('fa fa-angle-down')
        }
    },[detailOpen]);

    const genderOptions = ['Male' , 'Female'];
    const currencyOptions = ['USD Dollar' , 'Euro'];
    const cityOptions = ['Tehran' , 'Mashhad', 'Shiraz'];
    const orderTypeOptions = ['Card & SIMCard' , 'Card', 'SIMCard'];
    const statusOptions = ['Registered by Tourist' , 'Approved by admin'];

    const deliverPlaceOptions = [{ value: 1, label: 'Simorghe Tejarat' },{ value: 2, label: 'Shobe' }];
    const [place, setPlace] = useState(null);
    const [deliverPlace, setDeliverPlace] = useState(null);

    const deliverPlaceChangeHandler = (place) => {
        setPlace(place);
        setDeliverPlace(place.value);
    };

    const [showDialogBox, setShowDialogBox] = useState(false);
    const [dialogBoxType, setDialogBoxType] = useState(null);
    const [dialogBoxData, setDialogBoxData] = useState({
        actionMessage: '',
        referenceNumber: null,
        traceNumber: null,
        touristId: null,
        error: ''
    });

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoad(false);
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + bankToken,
                'traceNumber': '12345',
                'deviceId': 'IMEI'
            }
        };
        const data = {
            firstNameCard: firstName,
            lastNameCard: lastName,
            firstName: firstName,
            lastName: lastName,
            genderId: genderId,
            fatherName: fatherName ? fatherName : '',
            motherName: '',
            ancestorName: fatherName ? fatherName : '',
            birthDate: birthDate,
            birthPlaceCountryId: birthPlaceCountryId,
            cityOfBirth: '',
            nationalityId: nationalityId,
            maritalStatusId: 1,
            description: description ? description : '',
            photo: '',
            identificationNumber: identificationNumber,
            identificationIssueDate: identificationIssueDate,
            identificationExpirationDate: identificationExpirationDate,
            identificationDescription: '',
            identificationPhoto: identificationPhoto,
            deliverPlace: deliverPlace,
        };
        try {
            const res = await axios.post('https://setplus.stts.ir/tcms/api/Tourist/Add', data, config);
            setShowDialogBox(true);
            setDialogBoxType(true);
            setDialogBoxData({
                actionMessage: res.data.actionMessage,
                referenceNumber: res.data.referenceNumber,
                traceNumber: res.data.traceNumber,
                touristId: res.data.data.touristId,
                error: null,
            });
            setLoad(true);
        } catch (e) {
            console.log('Submit Form Error: ', e);
            setShowDialogBox(true);
            setDialogBoxType(false);
            setDialogBoxData({
                actionMessage: '',
                referenceNumber: null,
                traceNumber: null,
                touristId: null,
                error: e.toString(),
            });
            setLoad(true);
        }
    };

    return (
        <Fragment>
            {load === false
                ? <SpinnerModal/>
                : (
                    showDialogBox
                        ? <DialogBox data={dialogBoxData} type={dialogBoxType}/>
                        : null
                )
            }
            <div style={styles.containerStyle}>
                <div className='grid-5'>
                    <label style={{paddingTop: 5, paddingLeft: 5}}>{id}</label>
                    <label style={{paddingTop: 5, paddingLeft: 5}}>{firstName}</label>
                    <label style={{paddingTop: 5, paddingLeft: 5}}>{lastName}</label>
                    {tourist.status === 1
                        ? <i className='fa fa-times-circle'
                             style={{fontSize: 16, color: 'red', paddingLeft: '18%', paddingTop: 10}}/>
                        : <i className='fa fa-check-circle'
                             style={{fontSize: 16, color: 'green', paddingLeft: '18%', paddingTop: 10}}/>
                    }
                    <div style={{paddingLeft: 5}}>
                        <button
                            className='btn'
                            style={styles.btnStyle}
                            onClick={(e) => {
                                e.preventDefault();
                                setDetailOpen(!detailOpen)
                            }}
                        >
                            Details <i className={icon} />
                        </button>
                    </div>
                </div>
                <Collapse isOpened={detailOpen}>
                    <div className='grid-2' style={styles.detailStyle}>
                        <div className='grid-2'>
                            <div style={styles.colStyle}>
                                <div><strong>ID:</strong></div>
                                <div>
                                    <strong>User ID: </strong>
                                    <label style={{fontSize: 10, color: 'gray'}}>
                                        (The user who registered order)
                                    </label>
                                </div>
                                {orderType !== 3
                                    ? <div><strong>Tourist ID:</strong></div>
                                    : null
                                }
                                <div><strong>First Name:</strong></div>
                                <div><strong>Last Name:</strong></div>
                                <div><strong>Email:</strong></div>
                                <div><strong>Phone:</strong></div>
                                <div><strong>Gender ID:</strong></div>
                                <div><strong>Birth Place Country ID:</strong></div>
                                <div><strong>Birth Place Country:</strong></div>
                                <div><strong>Nationality ID:</strong></div>
                                <div><strong>Nationality:</strong></div>
                                <div><strong>Father Name:</strong></div>
                                <div><strong>Birth Date:</strong></div>
                                <div><strong>Identification Number:</strong></div>
                                <div><strong>Identification Issue Date:</strong></div>
                                <div><strong>Identification Expiration Date:</strong></div>
                                {orderType !== 3
                                    ? (
                                        <>
                                            <div><strong>Amount:</strong></div>
                                            <div><strong>Currency:</strong></div>
                                        </>
                                    )
                                    : null
                                }
                                <div><strong>Delivery City:</strong></div>
                                <div><strong>Delivery Address:</strong></div>
                                <div><strong>Arrival Date:</strong></div>
                                <div><strong>Description:</strong></div>
                                <div><strong>Order Type:</strong></div>
                                <div><strong>Price:</strong></div>
                                <div><strong>Status:</strong></div>
                                <div><strong>Create Date Time:</strong></div>
                            </div>
                            <div style={styles.colStyle}>
                                <div>{id}</div>
                                <div>{user_ID}</div>
                                {orderType !== 3
                                    ? <div>{tourist_ID ? tourist_ID : 'Not yet registered in bank system'}</div>
                                    : null
                                }
                                <div>{firstName}</div>
                                <div>{lastName}</div>
                                <div>{email}</div>
                                <div>{phone}</div>
                                <div>{genderOptions[genderId-1]}</div>
                                <div>{birthPlaceCountryId}</div>
                                <div>{birthPlaceCountry}</div>
                                <div>{nationalityId}</div>
                                <div>{nationality}</div>
                                <div>{fatherName ? fatherName : '-'}</div>
                                <div>{birthDate}</div>
                                <div>{identificationNumber}</div>
                                <div>{identificationIssueDate}</div>
                                <div>{identificationExpirationDate}</div>
                                {orderType !== 3
                                    ? (
                                        <>
                                            <div>{amount ? amount: '-'}</div>
                                            <div>{currencyOptions[currency-1]}</div>
                                        </>
                                    )
                                    : null
                                }
                                <div>{cityOptions[deliveryCity-1]}</div>
                                <div>
                                    {deliveryAddr.slice(0,15)}
                                    {deliveryAddr.length > 16 ? '...' : null}
                                </div>
                                <div>{arrivalDate}</div>
                                <div>
                                    {description
                                        ? description.slice(0,15)
                                        : '-'
                                    }
                                    {description.length > 16 ? '...' : null}
                                </div>
                                <div>{orderTypeOptions[orderType-1]}</div>
                                <div>{price}</div>
                                <div>{statusOptions[status-1]}</div>
                                <div>{createDateTime.slice(0,10)}</div>
                            </div>
                        </div>
                        <div>
                            <img
                                src={`data:image/jpeg;base64,${identificationPhoto}`}
                                alt='Passport Photo'
                                className='all-center'
                                style={styles.passImgStyle}
                            />
                            <br/>
                            {orderType !== 3
                                ? <div style={{width: '70%', marginLeft: '5%'}}>
                                    <label htmlFor='deliverPlace'>Deliver Place <span style={{color: 'red'}}>*</span></label>
                                    <Select
                                        options={deliverPlaceOptions}
                                        value={place}
                                        onChange={deliverPlaceChangeHandler}
                                        required
                                    />
                                </div>
                                : null
                            }
                            <a
                                className='btn text-center'
                                style={styles.djangoBtnStyle}
                                href={`https://admin.intravelcard.com/admin/requests/tourist/${id}/change/`}
                                target='_blank'
                            >
                                Go to django panel admin (edit)
                            </a>
                            <br/>
                            {orderType !== 3
                                ? <button
                                    className='btn'
                                    style={styles.confBtnStyle}
                                    onClick={onSubmit}
                                >
                                    Confirmed!
                                    Send to the bank
                                </button>
                                : null
                            }
                        </div>
                    </div>
                </Collapse>
            </div>
        </Fragment>
    );
};

TouristDetails1.prototype = {
    tourist: PropTypes.object.isRequired,
    bankToken: PropTypes.string.isRequired,
};

const styles = {
    containerStyle: {
        backgroundColor: '#eee',
        borderStyle: 'solid',
        borderColor: 'gray',
        borderWidth: 2,
        borderTopWidth: 0
    },
    btnStyle: {
        backgroundColor: '#2c3c85',
        color: 'white',
        height: 35,
        width: 75,
        padding: 0,
        margin: 2,
        borderRadius: 5,
    },
    detailStyle: {
        backgroundColor: 'white',
        padding: 10,
        marginTop: 2,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 5,
    },
    colStyle: {
        position: 'relative',
        width: '100%'
    },
    passImgStyle: {
        marginTop: 20,
        width: '90%',
        borderRadius: 5
    },
    djangoBtnStyle: {
        backgroundColor: '#2c3c85',
        color: 'white',
        height: 35,
        width: '50%',
        padding: 0,
        paddingTop: 4,
        marginTop: 10,
        marginLeft: '5%',
        borderRadius: 5,
    },
    confBtnStyle: {
        backgroundColor: '#2c3c85',
        color: 'white',
        height: 35,
        width: '50%',
        padding: 0,
        marginTop: 10,
        marginLeft: '5%',
        borderRadius: 5,
    },
};

export default TouristDetails1;
