import React, {Fragment, useEffect, useState} from 'react';
import {Collapse} from 'react-collapse';
import axios from 'axios';
import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';
import DialogBox from './DialogBox';
import '../Styles/App.css';

const TouristDetails2 = ({ tourist, bankToken }) => {
    const [load, setLoad] = useState(null);

    const { touristId, touristStatus,
        firstName, lastName, firstNameCard, lastNameCard, genderId, fatherName, motherName, ancestorName,
        birthDate, birthPlaceCountryId, birthPlaceCountryPersianTitle, cityOfBirth, nationalityId, nationalityPersianTitle,
        maritalStatusId, maritalStatusTitle, description,
        identificationTypeTitle, identificationNumber, identificationIssueDate, identificationExpirationDate,
        cardStatus, pan, deliverCardPlace, creationDate, requestEventDate } = tourist;

    const TouristStatusOptions = ['ثبت درخواست', 'درحال بررسی', 'تایید شده', 'رد شده', 'عدم مغایرت'];
    const genderOptions = ['مرد' , 'زن'];
    const CardStatusOptions = ['فعال', 'ضبط شده', 'مسدود', 'غیرفعال', 'غیرفعال اینترنتی', 'باطل', 'خارج از سرویس', 'معلق'];
    const deliverPlaceOptions = ['سیمرغ تجارت', 'شعبه'];

    const [detailOpen, setDetailOpen] = useState(false);
    const [icon, setIcon] = useState('fa fa-angle-down');

    useEffect(() => {
        if (detailOpen) {
            setIcon('fa fa-angle-up')
        } else {
            setIcon('fa fa-angle-down')
        }
    },[detailOpen]);

    const [showDialogBox, setShowDialogBox] = useState(false);
    const [dialogBoxType, setDialogBoxType] = useState(null);
    const [dialogBoxData, setDialogBoxData] = useState({
        actionMessage: '',
        referenceNumber: null,
        traceNumber: null,
        touristId: null,
        error: ''
    });
    const [imgData, setImgData] = useState('');

    const getPassImg = async (e) => {
        e.preventDefault();
        setLoad(false);
        const config = {
            headers: {
                Authorization: 'Bearer ' + bankToken,
                traceNumber: '12345',
                deviceId: 'IMEI'
            }
        };
        try {
            const res = await axios.get(`https://setplus.stts.ir/tcms/api/Tourist/GetIdentificationImage?id=${touristId}`, config);
            setImgData(res.data.data.identificationImage);
            setShowDialogBox(false);
            setLoad(true);
        } catch (e) {
            console.log('Get Tourist Passport Image Error: ', e);
            setShowDialogBox(true);
            setDialogBoxType(false);
            setDialogBoxData({
                actionMessage: '',
                referenceNumber: null,
                traceNumber: null,
                touristId: null,
                error: e.toString(),
            });
            setImgData('');
            setLoad(true);
        }
    };

    const showImg = () => {
        if (load === true) {
            return <img
                src={`data:image/jpeg;base64,${imgData}`}
                alt='Passport Photo'
                className='all-center'
                style={styles.passImgStyle}/>
        } else if (load === false) {
            return <Spinner/>
        } else if (load === null) {
            return null;
        }
    };

    return (
        <Fragment>
            {load === false
                ? null
                : (
                    showDialogBox
                        ? <DialogBox data={dialogBoxData} type={dialogBoxType}/>
                        : null
                )
            }
            <div style={styles.containerStyle}>
                <div className='grid-4'>
                    <label style={{paddingLeft: 5, paddingTop: 5}}>{tourist.touristId}</label>
                    <label style={{paddingLeft: 5, paddingTop: 5}}>{tourist.firstName}</label>
                    <label style={{paddingLeft: 5, paddingTop: 5}}>{tourist.lastName}</label>
                    <div style={{paddingLeft: 5}}>
                        <button
                            className='btn'
                            style={styles.btnStyle}
                            onClick={(e) => {
                                e.preventDefault();
                                setDetailOpen(!detailOpen)
                            }}
                        >
                            Details <i className={icon} />
                        </button>
                    </div>
                </div>
                <Collapse isOpened={detailOpen}>
                    <div className='grid-2' style={styles.detailStyle}>
                        <div className='grid-2'>
                            <div style={styles.colStyle}>
                                <div><strong>Tourist ID:</strong></div>
                                <div><strong>Tourist Status:</strong></div>
                                <div><strong>First Name:</strong></div>
                                <div><strong>Last Name:</strong></div>
                                <div><strong>First Name Card:</strong></div>
                                <div><strong>Last Name Card:</strong></div>
                                <div><strong>Gender:</strong></div>
                                <div><strong>Father Name:</strong></div>
                                <div><strong>Mother Name:</strong></div>
                                <div><strong>Ancestor Name:</strong></div>
                                <div><strong>Birth Date:</strong></div>
                                <div><strong>Birth Place Country ID:</strong></div>
                                <div><strong>Birth Place Country Persian Title:</strong></div>
                                <div><strong>City Of Birth:</strong></div>
                                <div><strong>Nationality ID:</strong></div>
                                <div><strong>Nationality Persian Title:</strong></div>
                                <div><strong>MaritalStatus ID:</strong></div>
                                <div><strong>MaritalStatus Title:</strong></div>
                                <div><strong>Description:</strong></div>
                                <div><strong>Identification Type Title:</strong></div>
                                <div><strong>Identification Number:</strong></div>
                                <div><strong>Identification Issue Date:</strong></div>
                                <div><strong>Identification Expiration Date:</strong></div>
                                <div><strong>Card Status:</strong></div>
                                <div><strong>Pan:</strong></div>
                                <div><strong>Deliver Card Place:</strong></div>
                                <div><strong>Creation Date:</strong></div>
                                <div><strong>Request Event Date:</strong></div>
                            </div>
                            <div style={styles.colStyle}>
                                <div>{touristId}</div>
                                <div>{TouristStatusOptions[touristStatus-1]}</div>
                                <div>{firstName}</div>
                                <div>{lastName}</div>
                                <div>{firstNameCard}</div>
                                <div>{lastNameCard}</div>
                                <div>{genderOptions[genderId-1]}</div>
                                <div>{fatherName ? fatherName : '-'}</div>
                                <div>{motherName ? motherName : '-'}</div>
                                <div>{ancestorName ? ancestorName : '-'}</div>
                                <div>{birthDate.slice(0,10)}</div>
                                <div>{birthPlaceCountryId}</div>
                                <div>{birthPlaceCountryPersianTitle}</div>
                                <div>{cityOfBirth ? cityOfBirth : '-'}</div>
                                <div>{nationalityId}</div>
                                <div>{nationalityPersianTitle}</div>
                                <div>{maritalStatusId ? maritalStatusId : '-'}</div>
                                <div>{maritalStatusTitle ? maritalStatusTitle: '-'}</div>
                                <div>
                                    {description
                                        ? description.slice(0,15)
                                        : '-'
                                    }
                                    {description.length > 16 ? '...' : null}
                                </div>
                                <div>{identificationTypeTitle}</div>
                                <div>{identificationNumber}</div>
                                <div>{identificationIssueDate.slice(0,10)}</div>
                                <div>{identificationExpirationDate.slice(0,10)}</div>
                                <div>{CardStatusOptions ? CardStatusOptions[cardStatus-1] : '-'}</div>
                                <div>{pan ? pan : '-'}</div>
                                <div>{deliverPlaceOptions[deliverCardPlace-1]}</div>
                                <div>{creationDate.slice(0,10)}</div>
                                <div>{requestEventDate.slice(0,10)}</div>
                            </div>
                        </div>
                        <div>
                            <div className='all-center'>
                                <button
                                    style={styles.imgBtnStyle}
                                    onClick={getPassImg}
                                >Get Passport Image</button>
                            </div>
                            <div>
                                {showImg()}
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
        </Fragment>
    );
};

TouristDetails2.prototype = {
    tourist: PropTypes.object.isRequired,
    bankToken: PropTypes.string.isRequired,
};

const styles = {
    containerStyle: {
        backgroundColor: '#eee',
        borderStyle: 'solid',
        borderColor: 'gray',
        borderWidth: 2,
        borderTopWidth: 0,
    },
    btnStyle: {
        backgroundColor: '#2c3c85',
        color: 'white',
        height: 35,
        width: 75,
        padding: 0,
        margin: 2,
        borderRadius: 5,
    },
    detailStyle: {
        backgroundColor: 'white',
        padding: 10,
        marginTop: 2,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 5,
    },
    colStyle: {
        position: 'relative',
        width: '100%'
    },
    passImgStyle: {
        marginTop: 10,
        width: '90%',
        borderRadius: 5
    },
    imgBtnStyle: {
        backgroundColor: '#2c3c85',
        color: 'white',
        height: 35,
        width: '30%',
        padding: 0,
        borderRadius: 5,
        borderWidth: 0,
    },
};

export default TouristDetails2;
