import React,{Fragment} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {Route, Switch} from 'react-router-dom';
import "./component/Styles/App.css";
import history from "./component/history";
import PrivateRoute from "./component/routing/PrivateRoute";

import AuthState from "./context/auth/AuthState";
import TokenState from "./context/token/TokenState";
import AlertState from "./context/alert/AlertState";

import Login from "./component/pages/Login";
import RegisteredTouristsList1 from "./component/pages/RegisteredTouristsList1";
import RegisteredTouristsList2 from "./component/pages/RegisteredTouristsList2";
import TouristInquiry from "./component/pages/TouristInquiry";
import CardServices from "./component/pages/CardServices";
import Factors from "./component/pages/Factors";
import Refund from "./component/pages/Refund";
import Lost from "./component/pages/Lost";
import TopUp from "./component/pages/TopUp";

function App() {
  return (
      <AuthState>
        <TokenState>
          <AlertState>
            <Router history={history} forceRefresh={true}>
              <Fragment>
                <Switch>
                  <Route exact path='/' component={Login}/>
                  <PrivateRoute exact path='/Registered-Tourists-List1' component={RegisteredTouristsList1}/>
                  <PrivateRoute exact path='/Registered-Tourists-List2' component={RegisteredTouristsList2}/>
                  <PrivateRoute exact path='/Tourist-Inquiry' component={TouristInquiry}/>
                  <PrivateRoute exact path='/Card-Services' component={CardServices}/>
                  <PrivateRoute exact path='/Factors-List' component={Factors}/>
                  <PrivateRoute exact path='/Refund-Requests' component={Refund}/>
                  <PrivateRoute exact path='/Lost-Requests' component={Lost}/>
                  <PrivateRoute exact path='/Top-Up-Requests' component={TopUp}/>
                </Switch>
              </Fragment>
            </Router>
          </AlertState>
        </TokenState>
      </AuthState>
  );
}

export default App;
