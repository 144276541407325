import React, {Fragment} from 'react';

const Spinner = () => {
    return (
        <Fragment>
            <img src='/Images/Spinner.gif' alt='loading...' style={{ width: 100, margin: 'auto', display: 'block' }}/>
        </Fragment>
    )
};

export default Spinner
