import React, {Fragment, useEffect, useState} from 'react';
import {Collapse} from 'react-collapse';
import PropTypes from 'prop-types';
import '../Styles/App.css';

const TopUpDetails = ({ req }) => {
    const { id, user_ID, firstName, lastName, email, pan, amount, currency, payType, done, createDateTime} = req;

    const currTypeOptions = [' €', ' $'];
    const payTypeOptions = ['Bitcoin', 'Cash', 'Other'];

    const [detailOpen, setDetailOpen] = useState(false);
    const [icon, setIcon] = useState('fa fa-angle-down');

    useEffect(() => {
        if (detailOpen) {
            setIcon('fa fa-angle-up')
        } else {
            setIcon('fa fa-angle-down')
        }
    },[detailOpen]);

    return (
        <Fragment>
            <div style={styles.containerStyle}>
                <div className='grid-5'>
                    <label style={{paddingTop: 5, paddingLeft: 5}}>{id}</label>
                    <label style={{paddingTop: 5, paddingLeft: 5}}>{firstName}</label>
                    <label style={{paddingTop: 5, paddingLeft: 5}}>{lastName}</label>
                    <label style={{paddingTop: 5, paddingLeft: 5}}>
                        {done === false
                            ? <i className='fa fa-times-circle'
                                 style={{fontSize: 16, color: 'red', paddingLeft: '6%', paddingTop: 7}}/>
                            : <i className='fa fa-check-circle'
                                 style={{fontSize: 16, color: 'green', paddingLeft: '6%', paddingTop: 7}}/>
                        }
                    </label>
                    <div style={{paddingLeft: 5}}>
                        <button
                            className='btn'
                            style={styles.btnStyle}
                            onClick={(e) => {
                                e.preventDefault();
                                setDetailOpen(!detailOpen)
                            }}
                        >
                            Details <i className={icon} />
                        </button>
                    </div>
                </div>
                <Collapse isOpened={detailOpen}>
                    <div className='grid-2' style={styles.detailStyle}>
                        <div className='grid-2'>
                            <div style={styles.colStyle}>
                                <div><strong>ID:</strong></div>
                                <div>
                                    <strong>User ID: </strong>
                                    <label style={{fontSize: 10, color: 'gray'}}>
                                        (The user who registered order)
                                    </label>
                                </div>
                                <div><strong>First Name:</strong></div>
                                <div><strong>Last Name:</strong></div>
                                <div><strong>Email:</strong></div>
                                <div><strong>Pan:</strong></div>
                                <div><strong>Amount:</strong></div>
                                <div><strong>Pay Type:</strong></div>
                                <div><strong>Done:</strong></div>
                                <div><strong>Create Date Time:</strong></div>
                            </div>
                            <div style={styles.colStyle}>
                                <div>{id}</div>
                                <div>{user_ID}</div>
                                <div>{firstName}</div>
                                <div>{lastName}</div>
                                <div>{email}</div>
                                <div>{pan}</div>
                                <div>{amount}{currTypeOptions[currency-1]}</div>
                                <div>{payTypeOptions[payType-1]}</div>
                                {done === false
                                    ? <div>No</div>
                                    : <div>Yes</div>
                                }
                                <div>{createDateTime.slice(0,10)}</div>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
        </Fragment>
    );
};

TopUpDetails.prototype = {
    req: PropTypes.object.isRequired,
};

const styles = {
    containerStyle: {
        backgroundColor: '#eee',
        borderStyle: 'solid',
        borderColor: 'gray',
        borderWidth: 2,
        borderTopWidth: 0,
    },
    btnStyle: {
        backgroundColor: '#2c3c85',
        color: 'white',
        height: 35,
        width: 75,
        padding: 0,
        margin: 2,
        borderRadius: 5,
    },
    detailStyle: {
        backgroundColor: 'white',
        padding: 10,
        marginTop: 2,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 5,
    },
    colStyle: {
        position: 'relative',
        width: '100%'
    }
};

export default TopUpDetails;
