import React, {Fragment, useContext, useEffect, useState} from 'react';
import AuthContext from '../../context/auth/authContext';
import AlertContext from '../../context/alert/alertContext';
import Alerts from '../layout/Alerts';
import history from "../history";
import axios from "axios";

const Login = () => {
    const t = localStorage.getItem('token');
    const [adminsID, setAdminsID] = useState([]);
    const [res, setRes] = useState(false);

    const auth = async () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        try {
            const admins = await axios.get('https://admin.intravelcard.com/api/auth/getAdmins/', config);
            admins.data.map(admin => adminsID.push(admin.id));
            const userId_token = await axios.get(`https://admin.intravelcard.com/api/auth/isAdmin/${t}`, config);
            if (userId_token.data[0]) {
                const TFList = adminsID.map(id => id === userId_token.data[0].user);
                if (TFList.includes(true)) {
                    setRes(true);
                    history.push('/Registered-Tourists-List1')
                } else {
                    setRes(false);
                }
            } else {
                setRes(false);
            }
        } catch (err) {
            console.log('Error!')
        }
    };
    const [haveRes, setHaveRes] = useState(false);

    const authContext = useContext(AuthContext);
    const alertContext = useContext(AlertContext);

    const { login, error, clearErrors } = authContext;
    const { setAlert } = alertContext;

    const [admin, setAdmin] = useState({
        user: '',
        pass: ''
    });

    const {user, pass} = admin;

    useEffect(() => {
        if (!haveRes && t) {
            auth();
            setHaveRes(true);
        }
        if (res === true) {
            history.push('/Registered-Tourists-List1')
        }
        if (error) {
            setAlert(error, 'danger');
            clearErrors();
        }
    },[haveRes, res, error]);

    const onChange = e => {
        setAdmin({...admin, [e.target.name]: e.target.value});
    };

    const onLoginSubmit = e => {
        e.preventDefault();
        login({
            username: user,
            password: pass
        });
    };

    return (
        <Fragment>
            <div id='login-container'>
                <form onSubmit={onLoginSubmit}>
                    <label htmlFor='user'>Username:</label>
                    <br/>
                    <input
                        type='text'
                        name='user'
                        value={user}
                        onChange={onChange}
                        maxLength='150'
                        required
                    />
                    <label htmlFor='pass'>Password:</label>
                    <br/>
                    <input
                        type='password'
                        name='pass'
                        value={pass}
                        onChange={onChange}
                        required
                    />
                    <input
                        className='btn btn-primary btn-block login-btn'
                        type='submit'
                        value='Login'
                    />
                    <div style={{marginTop: -15}}>
                        <Alerts/>
                    </div>
                </form>
            </div>
        </Fragment>
    )
};

export default Login;
