import React from 'react';
import Spinner from './Spinner';
import '../Styles/DialogBox.css';
import '../Styles/App.css';

const SpinnerModal = () => {
    return (
        <>
            <div className='popup all-center' style={{zIndex: 2}}>
                <div className='popup_inner' style={{backgroundColor: 'rgba(0,0,0,0)'}}>
                    <div className='container m-1' style={{padding: 30}}>
                        <Spinner/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SpinnerModal;
