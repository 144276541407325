import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Spinner from '../Spinner';

const CardInquiry = ({ bankToken }) => {
    const [error, setError] = useState('');
    const [load, setLoad] = useState(null);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [pan, setPan] = useState(null);
    const [msg, setMsg] = useState({
        actionMessage: '',
        referenceNumber: null,
        traceNumber: null
    });
    const [data, setData] = useState({
        cardStatus: null
    });

    const search = async (e) => {
        e.preventDefault();
        const config = {
            headers: {
                Authorization: 'Bearer ' + bankToken,
                traceNumber: '12345',
                deviceId: 'IMEI'
            }
        };
        const params = `firstName=${firstName}&lastName=${lastName}`;
        try {
            const res = await axios.get(`https://setplus.stts.ir/tcms/api/Tourist/GetByFilter?Page=1&Count=10&${params}`, config);
            if (res.data.data[0].pan === null || res.data.data[0].pan === 0) {
                setPan(0);
                setError('Pan Not Found!');
                setMsg(null);
                setData(null);
                setLoad(true);
            } else {
                setPan(res.data.data[res.data.data.length-1].pan);
                setError('');
                setMsg(null);
                setData(null);
            }
        } catch (e) {
            setPan(0);
            setError(e.toString());
            console.log('Get Pan Error: ', e);
            setMsg(null);
            setData(null);
            setLoad(true);
        }
    };

    const getStatus = async (e) => {
        e.preventDefault();
        setLoad(false);
        const config = {
            headers: {
                'Authorization': 'Bearer ' + bankToken,
                'traceNumber': '12345',
                'deviceId': 'IMEI'
            }
        };
        try {
            const res = await axios.get(`https://setplus.stts.ir/tcms/api/Card/GetStatus?pan=${Number(pan)}`, config);
            setMsg({
                actionMessage: res.data.actionMessage,
                referenceNumber: res.data.referenceNumber,
                traceNumber: res.data.traceNumber,
            });
            setData(res.data.data);
            setError('');
            setLoad(true);
        } catch (e) {
            setMsg(null);
            setData(null);
            setError(e.toString());
            console.log('Get Status Card Error: ', e);
            setLoad(true);
        }
    };

    const onChange1 = e => {
        setPan(e.target.value)
    };
    const onChange2 = e => {
        setFirstName(e.target.value)
    };
    const onChange3 = e => {
        setLastName(e.target.value)
    };

    const statusOptions = ['فعال', 'ضبط شده', 'مسدود', 'غیرفعال', 'غیرفعال اینترنتی', 'باطل', 'خارج از سرویس', 'معلق'];

    const showResult = () => {
        if (load === true && msg && data) {
            return (
                <div className='grid-2' style={{marginTop: 5, color: 'green'}}>
                    <div style={{width: '40%'}}>
                        <div><strong>Action Message:</strong></div>
                        <div><strong>Reference Number:</strong></div>
                        <div><strong>Trace Number:</strong></div>
                        <div><strong>Status:</strong></div>
                    </div>
                    <div style={{marginLeft: '-60%'}}>
                        <div>{msg.actionMessage}</div>
                        <div>{msg.referenceNumber}</div>
                        <div>{msg.traceNumber}</div>
                        <div>{statusOptions[data.cardStatus-1]}</div>
                    </div>
                </div>
            )
        } else if (load === true && error) {
            return (
                <div style={{color: 'red', marginTop: 5}}>
                    {error}
                </div>
            )
        } else if (load === false) {
            return <Spinner/>
        } else if (load === null) {
            return null;
        } else {
            return null;
        }
    };

    return (
        <Fragment>
            <div style={styles.contentStyle}>
                <form className='grid-3' onSubmit={search} style={{marginTop: 15}}>
                    <div style={{width:'60%'}}>
                        <label htmlFor='firstName'>First Name </label>
                        <input
                            type='text'
                            name='firstName'
                            value={firstName}
                            onChange={onChange2}
                            required
                        />
                    </div>
                    <div style={{width:'60%', marginLeft: '-30%'}}>
                        <label htmlFor='lastName'>Last Name </label>
                        <input
                            type='text'
                            name='lastName'
                            value={lastName}
                            onChange={onChange3}
                            required
                        />
                    </div>
                    <div style={{marginLeft: '-60%'}}>
                        <input
                            style={styles.searchBtnStyle}
                            type='submit'
                            value='Search'
                            className='btn'
                        />
                    </div>
                </form>
                <form onSubmit={getStatus}>
                    <label htmlFor='pan'>Pan </label>
                    <br/>
                    <input
                        type='number'
                        name='pan'
                        value={pan}
                        onChange={onChange1}
                        required
                    />
                    <input
                        style={styles.submitBtnStyle}
                        type='submit'
                        value='Submit'
                        className='btn'
                    />
                </form>
            </div>
            <div style={styles.resultStyle}>
                <h3>Result: </h3>
                {showResult()}
            </div>
        </Fragment>
    )
};

CardInquiry.propTypes = {
    bankToken: PropTypes.string.isRequired,
};

const styles = {
    contentStyle: {
        margin: 15,
        marginLeft: 20,
        borderStyle: 'solid',
        borderWidth: 0,
        borderBottomWidth: 2
    },
    submitBtnStyle: {
        backgroundColor: '#2c3c85',
        color: 'white',
        height: 35,
        width: 75,
        padding: 0,
        marginLeft: '5%',
        borderRadius: 5,
    },
    searchBtnStyle: {
        position: 'relative',
        backgroundColor: '#2c3c85',
        color: 'white',
        height: 35,
        width: 75,
        padding: 0,
        top: 24,
        borderRadius: 5,
    },
    resultStyle: {
        margin: 15,
        marginLeft: 20,
        paddingBottom: 10
    }
};

export default CardInquiry;
